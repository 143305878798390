import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  padding: 20px 15px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  height: 100%;

  @media (min-width: 768px) {
    align-items: center;
  }

  @media (orientation: landscape) and (min-width: 768px) and (max-width: 1023px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const Col8 = styled.div`
  flex: 0 0 100%;
  position: relative;

  @media (min-width: 768px) {
    flex: 0 0 66.66%;
  }

  &.system-page {
    > div {
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }

    .checkInfo {
      @media (min-width: 768px) {
        margin-top: 10px;
      }
    }
  }
`;

export const Col4 = styled.div`
  flex: 0 0 100%;

  @media (min-width: 768px) {
    flex: 0 0 33.33%;

    &.left-gap {
      padding-left: 30px;
    }
  }
`;

export const Title = styled.h1`
  font-family: 'Rubik', sans-serif;
  font-size: 1.75rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.25rem;
  cursor: default;
  text-align: center;
  margin: 16px 0;

  @media (min-width: 768px) {
    margin: 0 0 10px;
  }
`;

export const SmallTitle = styled.div`
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: #202124;
  cursor: default;
  margin-bottom: 10px;
  text-align: center;

  @media (min-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const SystemAudioStatus = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
`;

export const UserIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  i {
    padding: 5px 6px;
    background: #000;
    border-radius: 50%;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 15px;
  }
`;

export const CamMicErrorContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding-bottom: 10px;
`;

export const MicErrorButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: baseline;
  background: #ea4335;
  padding: 15px;
  border-radius: 50%;
  margin: 0 5px;
  border: 0;
  position: relative;
  cursor: pointer;
`;

export const CamErrorButton = styled(MicErrorButton)``;

export const ErrorMessage = styled.div`
  color: #ea4335;
  max-width: 250px;
  margin: 0 auto 10px;
`;

export const ModalContainer = styled.div`
  max-width: 500px;
  background-color: #fff;
  border-radius: 8px;
`;

export const ModalTitle = styled.h2`
  color: rgba(0,0,0,0.871);
  font-size: 22px;
  line-height: 1.2;
  padding: 15px 20px;
  margin: 0;
  letter-spacing: .1px;
`;

export const ModalDetails = styled.p`
  color: rgba(0, 0, 0, 0.541);
  font-size: 16px;
  line-height: 1.5;
  padding: 8px 20px 20px;
  margin: 0;

  img {
    vertical-align: middle;
  }
`;

export const ModalFooter = styled.div`
  padding: 15px;
  margin: 0 0 10px;
  text-align: right;
`;

export const ModalCloseButton = styled.button`
  border: 0;
  color: #000;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .25px;
  line-height: 36px;
  padding: 0 10px;
  border-radius: 4px;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const VideoErrorScreen = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

export const UnSupportBox = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  flex-direction: column;

  h2, p {
    margin: 0 5px;
  }
`;

export const BrowserBox = styled.div`
  img {
    width: 25px;
    margin: 5px;
  }
`;

export const CheckInfo = styled.div`
  &.left-show {
    @media (orientation: landscape) and (min-width: 768px) and (max-width: 1023px) {
      display: none;
    }
  }

  &.right-show {
    display: none;

    @media (orientation: landscape) and (min-width: 768px) and (max-width: 1023px) {
      margin-top: 15px;
      display: inline-flex;
    }
  }
`;