import React from "react";
import { useSetAtom } from "jotai";
import { getInterviewStatus } from "../../utils/getPercentage";
import { NO_FACE_MESSAGE, REQUIRED_TIME } from "../SystemCheck/Constants";
import Gauge from "components-gauge";
import {
  showNextAtom,
  showFinishAtom,
  noFaceAtom,
} from "../../atoms/interviewAtoms";

const InterViewFaceCheck = () => {
  const setNextButton = useSetAtom(showNextAtom);
  const setFinishButton = useSetAtom(showFinishAtom);
  const setNoFace = useSetAtom(noFaceAtom);

  const onEndEvent = () => {
    setNoFace(false);
    if (getInterviewStatus()) {
      setFinishButton(true);
      setNextButton(false);
    } else {
      setNextButton(true);
      setFinishButton(false);
    }
  };

  return (
    <div className="text-white flex">
      <Gauge
        baseColor="#36373a"
        baseStroke={20}
        end={-90.001}
        filledColor="#fff"
        filledStroke={20}
        fullCircle
        height={25}
        max={100}
        onEnd={onEndEvent}
        radius={10}
        start={-90}
        value={100}
        width={25}
        time={REQUIRED_TIME * 1000}
      />
      <span style={{ paddingLeft: "5px" }}>{NO_FACE_MESSAGE}</span>
    </div>
  );
};

export default InterViewFaceCheck;
