import React, { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useSetAtom, useAtom } from "jotai";
import SpeechRecognition from "react-speech-recognition";
import {
  showNextAtom,
  showFinishAtom,
  questionAtom,
  initQuestionLoader,
  noFaceAtom,
} from "../atoms/interviewAtoms";
import { loaderAtom } from "../atoms/loaderAtom";
import { transcriptAtom } from "../atoms/audioAtom";
import InterviewStyles from "../Styles/Interview.module.css";
import { REQUIRED_TIME } from "../Pages/SystemCheck/Constants";
import { getValidPercentage, getInterviewStatus } from "../utils/getPercentage";
import duration from "../utils/duration";
import {
  nextButtonSaveMedia,
  saveFacs,
  getNextQuestion,
} from "../services/mediaService";

const NextButton = (props) => {
  const time = useRef(null);
  const setShowNext = useSetAtom(showNextAtom);
  const setShowFinish = useSetAtom(showFinishAtom);
  const setShowLoader = useSetAtom(loaderAtom);
  const setNoFace = useSetAtom(noFaceAtom);
  const setInitQuestionLoader = useSetAtom(initQuestionLoader);
  const setQuestion = useSetAtom(questionAtom);
  const [transcriptString, setTranscriptString] = useAtom(transcriptAtom);

  const onFacsError = (error) => {
    console.log(error);
    setShowLoader(false);
    setShowFinish(true);
  };

  const fetchQuestion = async () => {
    setShowLoader(true);
    props.facs.start(false);
    await props.media.pause();
    const video_data = props.media.video();
    const audio_data = props.media.audio();
    const facs_data = props.logger.data();
    clearTimeout(time.current);
    const CIQ_ID = localStorage.getItem("candidate_interview_question_id");

    /**
     * Stop Listing when device is not mobile.
     */
    if (!isMobile) {
      SpeechRecognition.stopListening();
    }

    await getNextQuestion({
      candidate_interview_id: localStorage.getItem("candidate_interview_id"),
      current_datetime: new Date().toISOString(),
      text_response: transcriptString,
    })
      .then((res) => {
        let question_text = res.data.question.question_text;
        localStorage.setItem("question_text", question_text);

        const question_duration = duration(
          res?.data?.question?.question_duration || "00:00:10"
        );
        localStorage.setItem("question_duration", question_duration);

        let has_tts = res.data.question.has_tts;
        localStorage.setItem("has_tts", has_tts);
        localStorage.setItem("has_tts_Q", has_tts);

        let interview_status = res.data.candidate_interview.interview_status;
        localStorage.setItem("interview_status", interview_status);

        localStorage.setItem(
          "candidate_interview_question_id",
          res.data.candidate_interview_question_id
        );

        setQuestion({
          question_text: question_text,
          question_duration: question_duration,
        });
        setTranscriptString("");
        setShowNext(false);
        setShowLoader(false);
        setInitQuestionLoader(true);
        props.resetTranscript();

        saveFacs(CIQ_ID, { facs_data }).then(() => {
          nextButtonSaveMedia(CIQ_ID, video_data, audio_data);
        });
      })
      .catch(onFacsError);
  };

  const nextFinishCheck = () => {
    const startIndex = props.logger.getData().length;
    time.current = setTimeout(() => {
      const valid = getValidPercentage(props.logger.getData(), startIndex);
      clearTimeout(time.current);
      if (valid) {
        setNoFace(true);
      } else {
        setNoFace(false);
        if (getInterviewStatus()) {
          setShowFinish(true);
          setShowNext(false);
        } else {
          setShowNext(true);
          setShowFinish(false);
        }
        nextFinishCheck();
      }
    }, REQUIRED_TIME * 1000);
  };

  useEffect(() => {
    nextFinishCheck();

    return () => {
      clearTimeout(time.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button
      onClick={fetchQuestion}
      className={"btn round b-500 " + InterviewStyles.nextButton}
    >
      Next
    </button>
  );
};

export default NextButton;
