import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import Request, { API } from "../API";
import metaTags from "../utils/meta-tags";
import { useAtom } from "jotai";
import { loaderAtom } from "../atoms/loaderAtom";
import NewScreen from "../Components/new-screen";
import { omit } from "../lib/helpers";
import * as Constants from "../Constants";
import fetchTokenByAccount from "../services/fetch-token.service";
import fetchBrandData, {
  fetchBrandByAccount,
} from "../services/fetch-brand-data.service";

const getBrandCleaned = (brand) => {
  return {
    ...omit(Constants.Branding),
    ...omit(brand || {}),
    ...(!brand && { default: true }),
  };
};

const brandData = async (account_id) => {
  const HOST_NAME = window.location.hostname;
  if(Constants.isWhiteListed(HOST_NAME)) {
    const brand = await fetchBrandByAccount(account_id);
    const cleanedBrand = getBrandCleaned(brand);
    return cleanedBrand;
  }

  const brand = await fetchBrandData(HOST_NAME);
  const cleanedBrand = getBrandCleaned(brand);
  return cleanedBrand;
}

function WelcomeAccount() {
  const [brand, setBrand] = useState({});
  const { account_id } = useParams();
  const history = useHistory();
  const [loader, setShowLoader] = useAtom(loaderAtom);

  const basePath = useLocation();
  const query = new URLSearchParams(basePath.search);
  const jwt = query.get("jwt");

  const fetchData = async () => {
    setShowLoader(true);
    if (jwt) {
      try {
        const token = await fetchTokenByAccount(account_id);
        localStorage.setItem("access_token", token.access);
        localStorage.setItem("refresh_token", token.refresh);

        const webview = query.get('webview') ? `?webview=${query.get('webview')}` : ''
        if(webview) {
          localStorage.setItem('webview', query.get('webview'));
        }
        const { data } = await Request.post(
          API.SSO + webview,
          {
            data: jwt,
            activate_date: new Date().toISOString(), // ISO Date current time
          },
          {
            headers: {
              "X-ACCOUNT-ID": account_id,
            },
          }
        );
        let interview_url = new URL(data.interviews[0].interview_url);
        history.push(interview_url.pathname + interview_url.search);
      } catch {
        const data = await brandData(account_id);
        setBrand(data);
        metaTags(data);
        setShowLoader(false);
      }
    } else {
      const data = await brandData(account_id);
      setBrand(data);
      metaTags(data);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NewScreen
      branding={loader ? null : brand}
      defaultMessage={loader ? null : "Hey there,"}
      title={loader ? null : "Register for an Interview?"}
      buttonDetails={{
        text: "Home",
        url: brand?.brand_url,
      }}
    />
  );
}

export default WelcomeAccount;
