import React, { useEffect, useState, useReducer } from "react";
import { Redirect, useHistory } from "react-router";
import { Link } from "react-router-dom";
import VideoRecorder from "react-video-recorder-custom";
import Request, { API } from "../../API";
import VI_STATUS, { BUTTON_TITLE, VIDEO_RECORDING_LIMIT, VIDEO_START_AFTER } from "../../Constants";
import { checkedConstant } from "./Constants";
import { loaderAtom, loadingAtom } from "../../atoms/loaderAtom";
import { useSetAtom } from "jotai";
import AudioBar from "../../Components/AudioBar";
import NetworkSpeed from "../../Components/NetworkSpeed";
import CheckedIcon from "../../Components/Icons/checked-icon";
import {
  Title,
  SmallTitle,
  SystemAudioStatus,
  Wrapper,
  Col8,
  Col4,
  UserIcon,
  CamMicErrorContainer,
  MicErrorButton,
  CamErrorButton,
  ErrorMessage,
  ModalContainer,
  ModalTitle,
  ModalDetails,
  ModalFooter,
  ModalCloseButton,
  VideoErrorScreen,
  UnSupportBox,
  BrowserBox,
  CheckInfo
} from "./SystemCheck.style";
import { INIT_STEPS, reducerSteps } from "./reducer";
import titleCase from "../../utils/title-case";
import metaTags from "../../utils/meta-tags";
import getBrandCleaned from "../../utils/getBrandCleaned";
import toast from 'react-hot-toast';
import SystemCheckStyle from "../../Styles/SystemCheck.module.css";
import { browserSupportCheck } from "../../utils/browserSupport";
import duration from "../../utils/duration";
import { useModal } from 'react-hooks-use-modal';

const initialState = {
  micError: false,
  camError: false,
  initApp: true,
};

function errorReducer(state, action) {
  switch (action.type) {
    case "MIC":
      return {
        ...state,
        micError: action.payload,
      };
    case "CAM":
      return {
        ...state,
        camError: action.payload,
      };
    case "BOTH":
      return {
        micError: action.payload,
        camError: action.payload,
      };
    case "LOADED":
      return {
        ...state,
        initApp: false,
      };
    default:
      return state;
  }
}

function SystemCheck({ facs, media, logger }) {
  const history = useHistory();
  const [state, dispatch] = useReducer(errorReducer, initialState);
  const [stateStatus, dispatchStatus] = useReducer(reducerSteps, INIT_STEPS);
  const [user, setUser] = useState({});
  const [questionReady, setQuestionReady] = useState(false);
  const setShowLoader = useSetAtom(loaderAtom);
  const setLoading = useSetAtom(loadingAtom);
  const [notSupportedBrowser, setNotSupportedBrowser] = useState(false);
  const [brandData, setBrandData] = useState({});
  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
    focusTrapOptions: {
      clickOutsideDeactivates: false,
    },
  });

  const fetchQuestion = async () => {
    setShowLoader(true);
    setLoading(false);
    const current_datetime = new Date();

    await Request.post(API.CIQ, {
      candidate_interview_id: localStorage.getItem("candidate_interview_id"),
      current_datetime: current_datetime.toISOString(),
      text_response: "",
    })
      .then((res) => {
        localStorage.setItem("question_text", res.data.question.question_text);

        const question_duration = duration(res?.data?.question?.question_duration || "00:00:10");
        localStorage.setItem("question_duration", question_duration);

        localStorage.setItem("has_tts", res.data.question.has_tts);
        localStorage.setItem("has_tts_Q", res.data.question.has_tts);
        localStorage.setItem(
          "interview_status",
          res.data.candidate_interview.interview_status
        );
        localStorage.setItem(
          "candidate_interview_question_id",
          res.data.candidate_interview_question_id
        );

        let interview_started = new Date();
        localStorage.setItem(
          "interview_started",
          interview_started.toISOString()
        );
      })
      .catch((error) => {
        history.push(localStorage.getItem("APP_URL"));
      });

    setShowLoader(false);
    setQuestionReady(true);
  };

  useEffect(() => {
    setLoading(true);
    const userData = JSON.parse(
      decodeURIComponent(localStorage.getItem("user"))
    );
    setUser(userData);
    const cleanedBrand = getBrandCleaned(userData?.branding);
    setBrandData(cleanedBrand)
    metaTags(cleanedBrand);
    if (VI_STATUS.includes(userData.interview_status)) {
      setShowLoader(true);
      if (JSON.parse(localStorage.getItem('RESOURCE_LOADED'))) {
         // resource is loading if reload or block and unblock condition
        dispatchStatus({ type: "RESOURCE_LOADING" });
      }

      facs.onChange = () => {
        setShowLoader(true);
        dispatch({ type: "BOTH", payload: false });
        // on permission change for resource loading
        dispatchStatus({ type: "RESOURCE_LOADING" });
      };
      facs.onReady = () => {
        setShowLoader(false);
        // resource loaded
        dispatchStatus({ type: "RESOURCE_LOADED" });
        dispatch({ type: "LOADED" });
        localStorage.setItem('RESOURCE_LOADED', 1);
      };
      facs.onCamFail = () => {
        setShowLoader(false);
        dispatch({ type: "CAM", payload: true });
        dispatchStatus({ type: "SYSTEM_ERROR" });
      };
      facs.onMicFail = () => {
        setShowLoader(false);
        dispatch({ type: "MIC", payload: true });
        dispatchStatus({ type: "SYSTEM_ERROR" });
      };

      browserSupportCheck()
        .then(() => {
          facs.ready();
        }).catch(() => {
          setNotSupportedBrowser(true);
          setShowLoader(false);
        });
    } else {
      history.push(localStorage.getItem("APP_URL"));
    }

    document.body.classList.add("systemCheck");

    return () => {
      document.body.classList.remove("systemCheck");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (questionReady) {
    return <Redirect push to="/interview" />;
  }

  const isAlreadyTaken = () => {
    return (
      localStorage.getItem("interview_status") === "STARTED" ||
      user.interview_status === "STARTED"
    );
  };

  return (
    <>
      <div className="">
        <div className={SystemCheckStyle.row}>
          <div className={SystemCheckStyle.colLeft}>
            <img
              className={SystemCheckStyle.logo}
              src={brandData?.brand_logo}
              alt="logo"
            />
          </div>
          <div className={SystemCheckStyle.colRight}>
            <span>
              <span>
                {titleCase(user?.candidate?.first_name)} {titleCase(user?.candidate?.last_name)}
              </span>
              <span className={SystemCheckStyle.examType}>
                {user?.interview?.name}
              </span>
            </span>
            <i className={SystemCheckStyle.userIcon}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  fill="#fff"
                  d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"
                />
              </svg>
            </i>
          </div>
        </div>

        <div className={SystemCheckStyle.Flex}>
          <Wrapper>
            <Col8 className={"system-page"}>
              <div className={SystemCheckStyle.InnerBox}>
                <div className={SystemCheckStyle.box}>
                  {notSupportedBrowser ? (
                    <UnSupportBox>
                      <h2>Your browser is not supported!</h2>
                      <p>
                        Please use Chrome or a Chrome-based browser.
                      </p>
                    </UnSupportBox>
                  ) : stateStatus.init && !stateStatus.error ? (
                    <VideoErrorScreen>{stateStatus.permissionText}</VideoErrorScreen>
                  ) : (state.camError || state.micError) && stateStatus.error ? (
                    <VideoErrorScreen>
                      You have denied the access of camera and microphone
                      <br />
                      <br />
                    </VideoErrorScreen>
                  ) : VI_STATUS.includes(user.interview_status) ? (
                    <VideoRecorder
                      timeLimit={VIDEO_RECORDING_LIMIT}
                      onStartRecording={() => {
                        facs.start(true);
                        setTimeout(() => {
                          // Audio and Video Recording
                          dispatchStatus({ type: "AV_RECORDING" });
                        }, VIDEO_START_AFTER);
                      }}
                      onStopRecording={(m) => {
                        // Audio and Video Recording stopped/Recorded
                        dispatchStatus({ type: "AV_RECORDED" });
                        facs.start(false);
                        toast.dismiss();
                      }}
                      onRecordingComplete={(videoBlob) => {
                        // Do something with the video...
                        // console.log("videoBlob", videoBlob);
                      }}
                      onStopReplaying={() => {
                        // reset and go back to resource loaded
                        dispatchStatus({ type: "RESOURCE_LOADED" });
                      }}
                      onPreviewStart={() => {
                        // verifying
                        dispatchStatus({ type: "SYSTEM_CHECKING" });
                      }}
                      onPreviewStop={() => {
                        dispatchStatus({ type: "AV_RECORDED" });
                      }}
                      replayVideoAutoplayAndLoopOff
                      isOnInitially
                      onCheckEnd={() => {
                        // verify done
                        dispatchStatus({ type: "SYSTEM_CHECKED" });
                        // checking again user
                        dispatchStatus({ type: "FACE_CHECKS", payload: logger.data() });
                      }}
                      t={(msg) => BUTTON_TITLE[msg] || msg}
                      onError={(err) => {
                        dispatch({ type: "BOTH", payload: true });
                        dispatchStatus({ type: "SYSTEM_ERROR" });
                        console.log(err);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {VI_STATUS.includes(user.interview_status) && (
                  <SystemAudioStatus>
                    <AudioBar media={media} />
                  </SystemAudioStatus>
                )}
                {(state.camError || state.micError) && stateStatus.error ? (
                  <CamMicErrorContainer>
                    {state.camError && (
                      <CamErrorButton
                        title="Camera is blocked or not working."
                        onClick={open}
                      >
                        <svg
                          focusable="false"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M18 10.48V6c0-1.1-.9-2-2-2H6.83l2 2H16v7.17l2 2v-1.65l4 3.98v-11l-4 3.98zM16 16L6 6 4 4 2.81 2.81 1.39 4.22l.85.85C2.09 5.35 2 5.66 2 6v12c0 1.1.9 2 2 2h12c.34 0 .65-.09.93-.24l2.85 2.85 1.41-1.41L18 18l-2-2zM4 18V6.83L15.17 18H4z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </CamErrorButton>
                    )}
                    {state.micError && (
                      <MicErrorButton
                        title="Microphone is blocked or not working."
                        onClick={open}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          fill="#fff"
                        >
                          <path d="M0 0h24v24H0zm0 0h24v24H0z" fill="none"></path>
                          <path d="M19 11h-1.7c0 .74-.16 1.43-.43 2.05l1.23 1.23c.56-.98.9-2.09.9-3.28zm-4.02.17c0-.06.02-.11.02-.17V5c0-1.66-1.34-3-3-3S9 3.34 9 5v.18l5.98 5.99zM4.27 3L3 4.27l6.01 6.01V11c0 1.66 1.33 3 2.99 3 .22 0 .44-.03.65-.08l1.66 1.66c-.71.33-1.5.52-2.31.52-2.76 0-5.3-2.1-5.3-5.1H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c.91-.13 1.77-.45 2.54-.9L19.73 21 21 19.73 4.27 3z"></path>
                        </svg>
                      </MicErrorButton>
                    )}
                  </CamMicErrorContainer>
                ) : (
                  ""
                )}
              </div>
              <CheckInfo className={SystemCheckStyle.ChecksInfo + " checkInfo left-show"}>
                <CheckedIcon />
                {checkedConstant}
              </CheckInfo>
            </Col8>
            <Col4 className={`text-center ${notSupportedBrowser ? '' : 'left-gap'}`}>
              {notSupportedBrowser ? (
                <BrowserBox>
                  <div>
                    <img src="https://cdnjs.cloudflare.com/ajax/libs/browser-logos/71.0.0/chrome/chrome.svg" alt="chrome" loading="lazy" />
                    <img src="https://cdnjs.cloudflare.com/ajax/libs/browser-logos/71.0.0/brave/brave.svg" alt="brave" loading="lazy" />
                    <img src="https://cdnjs.cloudflare.com/ajax/libs/browser-logos/71.0.0/opera/opera.svg" alt="opera" loading="lazy" />
                    {/* <img src="https://cdnjs.cloudflare.com/ajax/libs/browser-logos/71.0.0/safari/safari.svg" alt="safari" loading="lazy" /> */}
                    <img src="https://cdnjs.cloudflare.com/ajax/libs/browser-logos/71.0.0/edge/edge.svg" alt="edge" loading="lazy" />
                  </div>
                  <p>
                    Please use a supported browser.
                  </p>
                  <Link
                    to={localStorage.getItem("APP_URL")}
                    className="btn btn-white round"
                  >
                    Back
                  </Link>
                </BrowserBox>
              ) : (<>
              <Title>{stateStatus.title}</Title>
                {stateStatus.joinButton && (
                  <UserIcon>
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path
                          fill="#fff"
                          d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"
                        />
                      </svg>
                    </i>
                  </UserIcon>
                )}
                <SmallTitle>{stateStatus.subTitle(isAlreadyTaken())}</SmallTitle>
                {(state.camError || state.micError) && stateStatus.error && (
                  <ErrorMessage>
                    We are unable to detect {state.camError && "camera "}
                    {state.camError && state.micError && "and "}
                    {state.micError && "microphone "}
                    in your system
                  </ErrorMessage>
                )}
                {stateStatus.joinButton && (
                  <button
                    onClick={fetchQuestion}
                    className="btn btn-white round"
                    type="button"
                  >
                    Join now
                  </button>
                )}
                {stateStatus.backButton && (
                  <Link
                    to={localStorage.getItem("APP_URL")}
                    className="btn btn-white round"
                  >
                    Back
                  </Link>
                )}
              </>)}
              <NetworkSpeed />
              <CheckInfo className={SystemCheckStyle.ChecksInfo + " checkInfo right-show"}>
                <CheckedIcon />
                {checkedConstant}
              </CheckInfo>
            </Col4>
          </Wrapper>
        </div>

        <Modal>
          <ModalContainer>
            <ModalTitle>Camera and microphone are blocked</ModalTitle>
            <ModalDetails>
              To proceed, your camera and microphone access is required.
              Click on {" "}
              <img
                alt="block mic and camera"
                aria-hidden="true"
                src="/block-camera-mic.svg"
                loading="lazy"
              />{" "}
              in your address bar to give the access.
            </ModalDetails>
            <ModalFooter>
              <ModalCloseButton onClick={close}>
                Dismiss
              </ModalCloseButton>
            </ModalFooter>
          </ModalContainer>
        </Modal>
      </div>
      {/* <ScreenRotate facs={facs} /> */}
    </>
  );
}

export default SystemCheck;
