export const resources = [
  { file: 'cogni.js', value: 30 },
  { file:'cogni-native-bindings.js', value: 60 },
  { file: 'cogni-native-bindings.data', value: 100 },
];

export const PERCENTAGE = 100;
export const INTERVAL_TIME = 2000;
// in seconds
export const TOTAL_TIME = 5;

export const TIME_INIT = 0;