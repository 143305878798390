import Request, { API } from "../API";

const fetchTokenByAccount = async (accountId) => {
  const { data } = await Request.post(
    API.TC,
    {},
    {
      transformRequest: (data, headers) => {
        delete headers.Authorization;
        return data;
      },
      headers: {
        "X-ACCOUNT-ID": accountId,
      },
    }
  );

  return Promise.resolve(data);
}

export default fetchTokenByAccount;