/**
 * check landscape orientation
 * @returns {boolean}
 */

const getLandscape = () => {
  const orientation =
    (window.screen.orientation || {}).type ||
    window.screen.mozOrientation ||
    window.screen.msOrientation;
  /*
    safari orientation - No support
    fallback: window.screen.availWidth > window.screen.availHeight
   */
  const orientationFallback =
    window.screen.availWidth > window.screen.availHeight;

  return orientation ? /landscape/.test(orientation) : orientationFallback;
};

export default getLandscape;
