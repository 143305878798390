const metaTags = (brand) => {
  if (!brand) {
    return;
  }
  const { brand_ico, brand_title, brand_description, brand_bg_color } = brand;

  document.querySelector("#favicon").href = brand_ico;
  document.querySelector("title").innerText = brand_title;
  document
    .querySelector("#description")
    .setAttribute("content", brand_description);
  document
    .querySelector("meta[name=theme-color]")
    .setAttribute("content", brand_bg_color);
};

export default metaTags;
