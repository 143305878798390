import axios from "axios";

let baseURL;

switch (window.location.hostname) {
  case "127.0.0.1":
    baseURL = `http://${window.location.hostname}:8000/api`;
    break;
  case "localhost":
    baseURL = `http://${window.location.hostname}:8000/api`;
    break;
  default:
    baseURL = "https://services.cognicue.in/api";
}

export const API = {
  CIQ: "/interview/candidate-interview-question/",
  CI: "/interview/candidate-interview/",
  TR: "/token/refresh/",
  TC: "/token/generate/",
  SSO: "/interview/candidate-interview/sso/",
  BRANDING: "/account/branding/",
  GENERATE_OTP: "/business/candidate-interview/otp/generate",
  VERIFY_OTP: "/business/candidate-interview/otp/verify",
};

export const queryParams = (key) => {
  const query = new URLSearchParams(window.location.search);
  return query.get(key);
};

const Request = axios.create({
  baseURL: baseURL,
});

const refreshAccessToken = async () => {
  try {
    const refresh_token =
      localStorage.getItem("refresh_token") || queryParams("refresh");

    if (!refresh_token) {
      throw new Error('No refresh token ' + window?.location?.href);
    }

    const response = await axios.post(
      baseURL + API.TR,
      {
        refresh: refresh_token,
      },
      {
        headers: {
          Authorization: `Bearer ${refresh_token}`,
        },
      }
    );
    const { access } = response?.data;
    localStorage.setItem("access_token", access);
    return Promise.resolve(access);
  } catch (error) {
    return Promise.reject(error);
  }
};

const resendStatusCode = [401, 403];

Request.interceptors.request.use(
  (config) => {
    if (config.data instanceof FormData) {
      config.headers[
        "Content-Type"
      ] = `multipart/form-data; boundary=${config.data._boundary}`;
    }

    config.headers.Authorization =
      "Bearer " +
      (localStorage.getItem("access_token") || queryParams("access"));
    return config;
  },
  (error) => Promise.reject(error)
);

Request.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      resendStatusCode.includes(error.response.status) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      Request.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${access_token}`;
      originalRequest.headers.Authorization = `Bearer ${access_token}`;
      return Request({
        method: originalRequest.method,
        baseURL: originalRequest.baseURL,
        url: originalRequest.url,
        headers: {
          ...originalRequest.headers,
        },
        data: originalRequest?.data,
      }).catch((error) => {
        return Promise.reject(error);
      });
    }
    return Promise.reject(error);
  }
);

export default Request;
