import Recorder from "recorder-js";

export function UserMedia(alert) {
  let chunks = [],
    audio = {},
    video = {},
    stream = null,
    recorder = {},
    audio_context = null,
    audio_recorder = {
      start: function () {
        return new Promise(() => {});
      },
      stop: function () {
        return new Promise(() => {});
      },
    },
    audio_recording = false;

  let w = alert;

  const messages = {
    incompatableBrowser: "Unsupported browser",
    camFailure: "Camera not connected",
    camSuccess: "Camera connected",
    micSuccess: "Microphone connected",
    micFailure: "Microphone not connected",
    noMediaRecorder: "MediaRecorder not found",
  };

  this.init = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((cameraStream) => {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(cameraStream);
        const processor = audioContext.createScriptProcessor(2048, 1, 1);

        stream = cameraStream;
        audio_context = audioContext;
        this.initialized = true;

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(processor);
        processor.connect(audioContext.destination);
        processor.onaudioprocess = () => {
          var array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          var values = 0;

          var length = array.length;
          for (var i = 0; i < length; i++) {
            values += array[i];
          }
          var average = values / length;
          this.setAudioState(Math.round(average) > 5);
        };
        try {
          audio_recorder = new Recorder(audioContext, {
            onAnalysed: (data) => null,
          });
          audio_recorder.init(stream);
        } catch {
          this.noMediaRecorder();
          w.error(messages.noMediaRecorder);
        }

        try {
          recorder = new MediaRecorder(stream);
        } catch {
          this.noMediaRecorder();
          w.error(messages.noMediaRecorder);
        }

        recorder.onstart = (e) => {};
        recorder.onerror = (e) => {};

        recorder.ondataavailable = (e) => {
          chunks.push(e.data);
        };
        recorder.onstop = (e) => {
          let completeBlob = new Blob(chunks, { type: chunks[0].type });
          video = completeBlob;
          chunks = [];
        };
        recorder.onpause = (e) => {
          let completeBlob = new Blob(chunks, { type: chunks[0].type });
          video = completeBlob;
          chunks = [];
        };
      })
      .catch((error) => {
        //w.error(messages.micFailure);
      });
  };

  this.noMediaRecorder = () => {};
  this.setAudioState = () => {};
  this.initialized = false;

  this.start = () => {
    if (recorder.state === "paused") {
      recorder.resume();
    } else if (recorder.state === "inactive") {
      recorder.start();
    }
    if (!audio_recording) {
      audio_recorder.start().then(() => (audio_recording = true));
    }
  };
  this.stop = async () => {
    recorder.state === "recording" && recorder.stop();
    if (stream && typeof stream.getTracks != "undefined") {
      var tracks = stream.getTracks();
      tracks.forEach(function (track, idx, arr) {
        track.stop();
      });
    }
    try {
      audio_context && audio_context.close();
    } catch (error) {
      console.log(error);
    }
    if (audio_recording) {
      const { blob } = await audio_recorder.stop();
      audio = blob;
      audio_recording = false;
      return Promise.resolve(blob);
    }
  };
  this.pause = async () => {
    if (recorder.state === "recording") {
      recorder.stop();
    }

    if (audio_recording) {
      const { blob } = await audio_recorder.stop();
      audio = blob;
      audio_recording = false;
      return Promise.resolve(blob);
    }
  };

  this.resume = () => {
    recorder.state === "paused" && recorder.resume();
  };
  this.download = () => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.download = "UserMedia.webm";
    a.href = window.URL.createObjectURL(audio);
    a.click();
    window.URL.revokeObjectURL(a.href);
  };
  this.data = () => {
    return {
      audio: audio,
      video: video,
    };
  };
  this.audio = () => {
    return audio;
  };
  this.video = () => {
    return video;
  };
}
