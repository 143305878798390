import { isMobile } from "react-device-detect";
import { STEPS, REQUIRED_PERCENTAGE } from "./Constants";

export const INIT_STEPS = STEPS.PERMISSION_CHECKING;

export const reducerSteps = (state, action) => {
  switch(action.type) {
    case "PERMISSION_CHECKING":
      return {
        ...STEPS.PERMISSION_CHECKING,
        facs_data: state?.facs_data
      };
    case "PERMISSION_CHECKED":
      return {
        ...STEPS.PERMISSION_CHECKED,
        facs_data: state?.facs_data
      };
    case "RESOURCE_LOADING":
      return {
        ...STEPS.RESOURCE_LOADING,
        facs_data: state?.facs_data
      };
    case "RESOURCE_LOADED":
      return {
        ...STEPS.RESOURCE_LOADED,
        facs_data: state?.facs_data
      };
    case "AV_RECORDING":
      return {
        ...STEPS.AV_RECORDING,
        facs_data: state?.facs_data
      };
    case "AV_RECORDED":
      return {
        ...STEPS.AV_RECORDED,
        facs_data: state?.facs_data
      };
    case "SYSTEM_CHECKING":
      return {
        ...STEPS.SYSTEM_CHECKING,
        facs_data: state?.facs_data
      };
    case "SYSTEM_CHECKED":
      return {
        ...STEPS.SYSTEM_CHECKED,
        facs_data: state?.facs_data
      };
    case "SYSTEM_ERROR":
      return {
        ...STEPS.SYSTEM_ERROR,
        facs_data: state?.facs_data
      };
    case "FACE_CHECKS":
      const data = action.payload.length ? action.payload : state.facs_data;
      const emptyObjects = data.filter(obj => !Object.keys(obj).length);
      const percentage = (emptyObjects.length / data.length) * 100;

      if (isMobile && percentage <= REQUIRED_PERCENTAGE.mobile) {
        return {
          ...state,
          facs_data: data
        };
      }

      if (!isMobile && percentage <= REQUIRED_PERCENTAGE.desktop) {
        return {
          ...state,
          facs_data: data
        };
      }

      return {
        ...STEPS.FACS_CHECKED,
        facs_data: data
      }
    default:
      return state;
  }
}
