import * as ACTION from "../actions/otpformAction"

export const initState = {
  submitIsDisable: true,
  submitText: "Verify",
  resendIsDisable: false,
  otpValue: "",
  NOT_VALID: false,
  RESEND_ERROR: false,
};

const reducer = (state, action) => {
  if (action.type === ACTION.INPUT) {
    return {
      ...state,
      submitIsDisable: false,
      otpValue: action.payload,
      NOT_VALID: false,
      RESEND_ERROR: false
    };
  }

  if (action.type === ACTION.SUBMIT) {
    return {
      ...state,
      submitText: 'Verifying...',
      submitIsDisable: true
    };
  }

  if (action.type === ACTION.SUBMITTED) {
    return {
      ...state,
      submitText: 'Verify',
      submitIsDisable: false,
      resendIsDisable: false
    }
  }

  if (action.type === ACTION.RESET) {
    return {
      ...state,
      otpValue: ''
    }
  }

  if (action.type === ACTION.NOT_VALID) {
    return {
      ...state,
      NOT_VALID: true,
      RESEND_ERROR: false
    }
  }

  if (action.type === ACTION.RESEND_ERROR) {
    return {
      ...state,
      NOT_VALID: false,
      RESEND_ERROR: true
    }
  }

  return state;
}

export default reducer;
