import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router";
import { useSetAtom, useAtomValue } from "jotai";
import { loaderAtom } from "../atoms/loaderAtom";
import { transcriptAtom } from "../atoms/audioAtom";
import {
  noFaceAtom,
  showFinishAtom,
  showNextAtom,
} from "../atoms/interviewAtoms";
import InterviewStyles from "../Styles/Interview.module.css";
import { REQUIRED_TIME } from "../Pages/SystemCheck/Constants";
import { getValidPercentage, getInterviewStatus } from "../utils/getPercentage";
import {
  saveFacs,
  saveAudioWithFinish,
  saveVideo,
} from "../services/mediaService";

const FinishButton = ({ facs, media, logger }) => {
  const time = useRef(null);
  const setShowLoader = useSetAtom(loaderAtom);
  const setShowFinish = useSetAtom(showFinishAtom);
  const setShowNext = useSetAtom(showNextAtom);
  const setNoFace = useSetAtom(noFaceAtom);
  const transcriptString = useAtomValue(transcriptAtom);
  const history = useHistory();

  const onAPIError = (error) => {
    media.start();
    console.log(error);
    setShowLoader(false);
  };

  const onComplete = () => {
    media.stop();
    history.push("/exit");
  };

  const onSaveMediaError = (error) => console.log(error);

  const handleClick = async () => {
    facs.stop();
    await media.pause();

    media.setAudioState = () => {};
    setShowLoader(true);

    const CIQ_ID = localStorage.getItem("candidate_interview_question_id");
    const facs_data = logger.data();
    const video_data = media.video();
    const audio_data = media.audio();
    clearTimeout(time.current);

    await saveFacs(CIQ_ID, {
      facs_data,
      text_response: transcriptString,
    })
      .then(() => {
        saveAudioWithFinish(CIQ_ID, audio_data)
          .catch(onSaveMediaError)
          .finally(() => {
            saveVideo(CIQ_ID, video_data).then(onComplete).catch(onAPIError);
          });
      })
      .catch(onAPIError);
  };

  const nextFinishCheck = () => {
    const startIndex = logger.getData().length;
    time.current = setTimeout(() => {
      const valid = getValidPercentage(logger.getData(), startIndex);
      if (valid) {
        setNoFace(true);
      } else {
        clearTimeout(time.current);
        setNoFace(false);
        if (getInterviewStatus()) {
          setShowFinish(true);
          setShowNext(false);
        } else {
          setShowNext(true);
          setShowFinish(false);
        }
        nextFinishCheck();
      }
    }, REQUIRED_TIME * 1000);
  };

  useEffect(() => {
    nextFinishCheck();

    return () => {
      clearTimeout(time.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button
      onClick={handleClick}
      className={"btn round b-500 " + InterviewStyles.nextButton}
    >
      Finish
    </button>
  );
};

export default FinishButton;
