import React, { useState, useEffect } from "react";
import { Bar, AudioBox } from "./AudioBar.style";
import { useSetAtom } from 'jotai';
import { syncUserActionAtom } from "../../atoms/audioAtom";
import useIsMounted from "../../hooks/use-is-mounted.hook";
import MicShow from "../Mic";

function AudioBar({ media, interviewPage = false, wrapperClass = "" }) {
  const [audioState, setAudioState] = useState(false);
  const setSyncUserActionAtom = useSetAtom(syncUserActionAtom);
  const isMounted = useIsMounted();

  useEffect(() => {
    media.setAudioState = setAudioState;
    media.initialized || media.init();
  }, [media]);

  useEffect(() => {
    isMounted && setSyncUserActionAtom(audioState);
  }, [isMounted, audioState, setSyncUserActionAtom])

  return (
    <>
      {interviewPage && <MicShow audioState={audioState} />}
      {audioState ? (
        <AudioBox className={`${audioState ? "active" : ""} ${wrapperClass}`}>
          <Bar className="audioBar" />
          <Bar className="audioBar" />
          <Bar className="audioBar" />
        </AudioBox>
      ) : (
        ""
      )}
    </>
  );
}

export default AudioBar;
