import styled from "styled-components";
import {
  STATUS_CHECKING,
  STATUS_GOOD,
  STATUS_ERROR,
  STATUS_SLOW,
} from "./Constants";

const COLORS = {
  [STATUS_CHECKING]: "#6c757d",
  [STATUS_GOOD]: "#28a745",
  [STATUS_ERROR]: "#dc3545",
  [STATUS_SLOW]: "#ffc107",
};

export const NetWorkBoxContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
  color: ${(props) => COLORS[props.type] || COLORS[STATUS_CHECKING]};
`;

export const NetworkInner = styled.div`
  display: flex;
  align-items: flex-end;
  line-height: 1;
  font-size: 0.875rem;
  font-weight: 500;
`;

export const Bars = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: 5px;

  span {
    width: 3px;
    margin: 0 2px;
    border-radius: 3px;
    background-color: ${(props) => COLORS[props.type] || COLORS[STATUS_CHECKING]};

    &:first-child {
      height: 5px;
    }

    &:nth-child(2) {
      height: 10px;
    }

    &:nth-child(3) {
      height: 15px;
    }
  }
`;
