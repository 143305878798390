import React, { useEffect, useState } from "react";
import { useSetAtom, useAtomValue } from "jotai";
import { isMobile } from "react-device-detect";
import { systemSpeakingAtom, questionAtom } from "../atoms/interviewAtoms";
import useSpeech from "../hooks/use-speech";
import storageService from "../services/storageService";

const Question = (props) => {
  const { facs, media, resetTranscript, listenContinuously } = props;
  const setSystemSpeaking = useSetAtom(systemSpeakingAtom);
  const question = useAtomValue(questionAtom);
  const [startListening, setStartListening] = useState(false);
  const { messageSpeech, start, isSpeechError } = useSpeech({
    text: question?.question_text,
  });

  const onEnd = () => {
    /**
     * Start Listing when device is not mobile.
     * props listenContinuously
     * listenContinuously is calling SpeechRecognition.startListening with EN-IN and continue
     */
    if (!isMobile) {
      setStartListening(true);
    }
    facs.start(true);
    media.start();
    setSystemSpeaking(false);
  };

  const onStart = () => {
    setSystemSpeaking(true);
    setStartListening(false);
  }

  messageSpeech.onend = onEnd;
  messageSpeech.onstart = onStart;

  const speak = () => {
    const tts = storageService.getTTS() === "true";
    const text = question?.question_text;
    if (text && tts) {
      start();
      storageService.setTTS(false);
    }

    if (isSpeechError) {
      onEnd();
    }
  };

  useEffect(() => {
    const value = storageService.getTTS_Q();
    if (value) {
      storageService.setTTS(value);
    }
  }, []);

  useEffect(() => {
    if (startListening) {
      resetTranscript();
      listenContinuously();
    }
    // eslint-disable-next-line
  }, [startListening]);

  if (!question?.question_text) {
    return null;
  }

  return (
    <>
      {speak()}
      <h1>{question.question_text}</h1>
    </>
  );
};

export default Question;
