import React from "react";
import { useAtomValue, useSetAtom } from "jotai";
import Gauge from "components-gauge";
import {
  questionAtom,
  showNextAtom,
  showFinishAtom,
  initQuestionLoader,
  noFaceAtom,
} from "../../atoms/interviewAtoms";
import {
  getValidPercentage,
  getInterviewStatus,
} from "../../utils/getPercentage";

const InterViewCircle = ({ logger }) => {
  const question = useAtomValue(questionAtom);
  const setNextButton = useSetAtom(showNextAtom);
  const setFinishButton = useSetAtom(showFinishAtom);
  const setInitQuestion = useSetAtom(initQuestionLoader);
  const setNoFace = useSetAtom(noFaceAtom);
  const startIndex = logger.getData().length;

  const onEndEvent = () => {
    const valid = getValidPercentage(logger.getData(), startIndex);
    setInitQuestion(false);
    if (valid) {
      setNoFace(true);
      return;
    }

    setNoFace(false);
    if (getInterviewStatus()) {
      setFinishButton(true);
      setNextButton(false);
    } else {
      setFinishButton(false);
      setNextButton(true);
    }
  };

  return (
    <Gauge
      baseColor="#36373a"
      baseStroke={20}
      end={-90.001}
      filledColor="#fff"
      filledStroke={20}
      fullCircle
      height={40}
      max={100}
      onEnd={onEndEvent}
      radius={10}
      start={-90}
      value={100}
      width={40}
      time={question.question_duration}
    />
  );
};

export default InterViewCircle;
