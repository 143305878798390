/**
 * STEPS inner components
 *  - init: initialize status
 *  - title
 *  - subtitle
 *  - back button
 *  - join button
 */

export const STEPS = {
  PERMISSION_CHECKING: {
    init: true,
    title: 'Checking permissions...',
    subTitle: () => '',
    permissionText: 'Waiting for approval',
    backButton: true,
    joinButton: false,
    error: false
  },
  PERMISSION_CHECKED: {
    init: true,
    title: '',
    permissionText: '',
    subTitle: () => '',
    backButton: true,
    joinButton: false,
    error: false
  },
  RESOURCE_LOADING: {
    init: true,
    title: 'Getting ready...',
    subTitle: () => 'Checking input & output devices',
    permissionText: 'Checking input & output devices',
    backButton: true,
    joinButton: false,
    error: false
  },
  RESOURCE_LOADED: {
    init: false,
    title: 'Record test video',
    subTitle: () => 'Lets see if you are audible and visible',
    permissionText: '',
    backButton: false,
    joinButton: false,
    error: false
  },
  AV_RECORDING: {
    init: false,
    title: 'Recording...',
    subTitle: () => 'Try speaking "ABC, 123" or anything you like',
    permissionText: '',
    backButton: false,
    joinButton: false,
    error: false
  },
  AV_RECORDED: {
    init: false,
    title: 'Verify test video',
    subTitle: () => 'Play the button to preview your video.',
    permissionText: '',
    backButton: false,
    joinButton: false,
    error: false
  },
  SYSTEM_CHECKING: {
    init: false,
    title: 'Playing the video...',
    subTitle: () => 'Checking the audio and video quality',
    permissionText: '',
    backButton: false,
    joinButton: false,
    error: false
  },
  SYSTEM_CHECKED: {
    init: false,
    title: 'Ready to Join !',
    subTitle: (taken) => taken ? 'Your interviewer is already waiting for you.' : 'Your interviewer is waiting for you.',
    permissionText: '',
    backButton: false,
    joinButton: true,
    error: false
  },
  SYSTEM_ERROR: {
    init: true,
    title: '',
    subTitle: () => '',
    permissionText: '',
    backButton: true,
    joinButton: false,
    error: true
  },
  FACS_CHECKED: {
    init: false,
    title: 'Verification failed',
    subTitle: () => 'Please try recording again, where your face is clearly visible.',
    permissionText: '',
    backButton: false,
    joinButton: false,
    error: false
  },
};

export const checkedConstant = "Check your audio and video";

export const REQUIRED_PERCENTAGE = {
  mobile: 80,
  desktop: 80
};

export const REQUIRED_TIME = 10; // seconds

export const NO_FACE_MESSAGE = "Please pay attention to the screen.";
