import styled, {createGlobalStyle  } from "styled-components";

export const GlobalStyle = createGlobalStyle `
  #root, .App {
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  padding: 10px 15px;
  margin: 0 auto;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Section = styled.div`
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media (min-width: 980px) {
    transform: translateY(-100px);
  }
`;

export const Logo = styled.div`
  width: 50%;

  .logo {
    max-width: 100px;
    max-height: 30px;

    @media (min-width: 768px) {
      max-width: 150px;
      max-height: 60px;
    }

    @media (orientation: landscape) and (max-width: 849px) {
      max-width: 100px;
      max-height: 30px;
    }
  }
`;

export const UserDetails = styled.div`
  width: 50%;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
`;

export const UserTitle = styled.span``

export const UserSubTitle = styled.span`
  font-size: 10px;
  display: block;
`;

export const UserIcon = styled.i`
  padding: 5px 6px;
  background: #000;
  border-radius: 50%;
  border: 1px solid #000;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 15px;
  }
`;

export const Footer = styled.footer`
  padding: 15px;
  text-align: center;

  @media (orientation: landscape) {
    padding: 0 15px;
  }
`;

export const MainContainer = styled.main`
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;

  @media (orientation: landscape) {
    h1 {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 0;
    }
  }
`;