import styled, { css } from "styled-components";

export const ButtonStyle = styled.button`
  border-radius: ${(props) => props.rounded ? 25 : 3}px;
  color: #fff;
  border-color: #000;
  background: #000;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 10px 33px;
  font-size: 1rem;
  line-height: 1;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  ${props => props.large && css`min-width: 150px`}
`;