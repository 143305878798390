import React, { useState, useEffect } from "react";
import * as Constants from "../../Constants";
import fetchBrandData from "../../services/fetch-brand-data.service";
import metaTags from "../../utils/meta-tags";
import { loaderAtom } from "../../atoms/loaderAtom";
import { useSetAtom } from "jotai";
import NewScreen from "../../Components/new-screen";

function Home() {
  const [candidateData, setCandidateData] = useState({});
  const setShowLoader = useSetAtom(loaderAtom);

  useEffect(() => {
    localStorage.clear();
    const HOST_NAME = window.location.hostname;
    setShowLoader(true);
    if (Constants.isWhiteListed(HOST_NAME)) {
      fetchDummy();
    } else {
      fetchBrandData(HOST_NAME)
        .then((data) => {
          setCandidateData({
            ...Constants.CandidateInterview,
            branding: data,
            buttonText: data.buttonText ?? Constants.BUTTON_TEXT.home,
            titleText: data.titleText ?? Constants.TITLE_TEXT.home,
          });
          metaTags(data);
          setShowLoader(false);
        })
        .catch(() => {
          fetchDummy();
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDummy = async () => {
    await new Promise((r) => setTimeout(r, 2000));
    const response = { ...Constants.CandidateInterview };
    response.branding.default = true;
    response.buttonText = Constants.BUTTON_TEXT.default;
    response.titleText = Constants.TITLE_TEXT.default;
    setCandidateData(response);
    metaTags(response?.branding);
    setShowLoader(false);
  };

  return (
    <NewScreen
      branding={candidateData?.branding}
      candidate={candidateData?.candidate}
      defaultMessage={candidateData?.default_msg}
      title={candidateData?.titleText}
      buttonDetails={{
        text: candidateData?.buttonText,
        url: Constants.isWhiteListed(window.location.hostname) ? candidateData?.branding?.interview_url : candidateData?.branding?.brand_url,
      }}
    />
  );
}

export default Home;
