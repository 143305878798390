import React, { useEffect, useState } from "react";
import netest from "netest";
import { Bars, NetWorkBoxContainer, NetworkInner } from "./NetworkSpeed.style";
import {
  netestOptions,
  statusText,
  STATUS_CHECKING,
  STATUS_GOOD,
  STATUS_ERROR,
  STATUS_SLOW,
} from "./Constants";

function BarContainer(props) {
  return (
    <Bars {...props}>
      <span></span>
      <span></span>
      <span></span>
    </Bars>
  );
}

function NetworkSpeed() {
  const [networkStatus, setNetworkStatus] = useState(STATUS_CHECKING);

  useEffect(() => {
    netest(netestOptions)
      .then((result) => {
        if (!result.isSlow) {
          setNetworkStatus(STATUS_GOOD);
        } else {
          setNetworkStatus(STATUS_SLOW);
        }
      })
      .catch((error) => {
        if (error.hasFailed) {
          setNetworkStatus(STATUS_ERROR);
        }
      });
  }, []);

  return (
    <NetWorkBoxContainer type={networkStatus}>
      <NetworkInner>
        <BarContainer type={networkStatus} /> {statusText[networkStatus]}
      </NetworkInner>
    </NetWorkBoxContainer>
  );
}

export default NetworkSpeed;
