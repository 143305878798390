import React from "react";
import {
  GlobalStyle,
  Container,
  LeftContainer,
  RightContainer,
  SkewContainer,
  SkewBox,
  DetailsLeft,
  Terms,
  ExternalLink,
  Image,
  Title,
  SubTitle,
} from "./new-screen.style";

const NewScreenOTP = ({
  branding,
  children
}) => {
  return (
    <>
      <GlobalStyle
        startColor={branding?.brand_bg_color}
        endColor={branding?.brand_gd_color}
        text={branding?.brand_text_color}
      />
      <Container level={0}>
        <LeftContainer>
          <SkewContainer>
            <SkewBox />
          </SkewContainer>
        </LeftContainer>
      </Container>
      <Container level={1}>
        <LeftContainer>
          {branding && (
            <>
              <DetailsLeft>
                <ExternalLink href={branding?.brand_url}>
                  <Image
                    src={
                      branding?.default
                        ? branding?.welcome_logo
                        : branding?.brand_logo
                    }
                    alt="logo"
                    loading="lazy"
                    decoding="async"
                  />
                </ExternalLink>
                <Title>{branding?.brand_title}</Title>
                <SubTitle>{branding?.brand_description}</SubTitle>
              </DetailsLeft>
              <Terms>
                Before signing in please check the{" "}
                <ExternalLink
                  href={branding?.brand_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  terms &amp; conditions
                </ExternalLink>
              </Terms>
            </>
          )}
        </LeftContainer>
        <RightContainer flexi>
          {children}
        </RightContainer>
      </Container>
    </>
  );
};

export default NewScreenOTP;
