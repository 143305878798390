import * as Sentry from "@sentry/react";

function hasGetUserMedia() {
  return !!(
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia
  );
}

const issues = {
  user_media: 'issue with navigator.getUserMedia',
  no_media: 'issue with navigator.mediaDevices and navigator.mediaDevices.getUserMedia'
}

export async function browserSupportCheck() {
  const webview = !!localStorage.getItem('webview');

  try {
    if (webview) {
      return Promise.resolve(true);
    }

    await navigator.permissions.query({ name: "microphone" });
  } catch (err) {
    Sentry.captureException(err);
    return Promise.reject(err);
  }

  try {
    if (webview) {
      return Promise.resolve(true);
    }

    await navigator.permissions.query({ name: "camera" });
  } catch (err) {
    Sentry.captureException(err);
    return Promise.reject(err);
  }

  if (!hasGetUserMedia()) {
    Sentry.captureException(issues.user_media);
    return Promise.reject(new Error(issues.user_media));
  }

  if (
    !("mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices)
  ) {
    Sentry.captureException(issues.no_media);
    return Promise.reject(new Error(issues.no_media));
  }

  return Promise.resolve(true);
}
