import styled, { keyframes } from "styled-components";

const heightAnimation = keyframes`
  0% {
    transform: scaleY(0.8)
  }

  50% {
    transform: scaleY(1)
  }

  to {
    transform: scaleY(0.8)
  }
`;

export const AudioBox = styled.div`
  display: flex;
  align-items: center;
  width: 26px;
  height: 26px;
  justify-content: center;
  gap: 0.15rem;
  border-radius: 50%;
  background-color: #1a73e8;

  .audioBar {
    transition: unset;
    animation: none;

    &:nth-child(1) {
      height: 0.25em;
    }
    &:nth-child(2) {
      height: 0.25em;
    }
    &:nth-child(3) {
      height: 0.25em;
    }
  }

  &.active {
    .audioBar {
      transition: height 200ms steps(6,jump-none);
      animation: ${heightAnimation} 600ms steps(9,jump-none) 0s infinite;

      &:nth-child(1) {
        height: 0.4375em;
      }
      &:nth-child(2) {
        height: 0.8125em;
      }
      &:nth-child(3) {
        height: 0.4375em;
      }
    }
  }
`;

export const Bar = styled.div`
  background: #fff;
  width: 0.25em;
  border-radius: 0.125em/0.125em;
`;