const titleCase = (string) => {
  if (string === null || string === undefined) return "";

  let sentence  = string.toString().split(' ');
  for (var i = 0; i < sentence.length; i++) {
    let word = sentence[i];
    if (word.toUpperCase() === word){
        continue;
    }else{
        sentence[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  }
  return sentence.join(' ');
};

export default titleCase;
