import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import Request, { API } from "../../API";
import * as Constants from "../../Constants";
import { omit } from "../../lib/helpers";
import metaTags from "../../utils/meta-tags";
import { useAtom, useSetAtom } from "jotai";
import { loaderAtom } from "../../atoms/loaderAtom";
import maskedAtom from "../../atoms/maskedAtom";
import NewScreen from "../../Components/new-screen";
import messages, { wrongURL } from "./messages";
import fetchBrandData, {
  fetchBrandByAccount,
} from "../../services/fetch-brand-data.service";
// import fetchTokenByAccount from "../../services/fetch-token.service";
import NewScreenOTP from "../../Components/new-screen/NewScreenOTP";
import OTPScreen from "./OTPScreen";
import duration from "../../utils/duration";
import { storeAccess } from "../../utils/storeAccess";

const getBrandCleaned = (brand) => {
  return {
    ...omit(Constants.Branding),
    ...omit(brand || {}),
    ...(!brand && { default: true }),
  };
};

function WelcomeCandidate() {
  const [candidateData, setCandidateData] = useState({});
  const { account_id, candidate_interview_id } = useParams();
  const [candidateMessages, setCandidateMessages] = useState({});
  const [brandData, setBrandData] = useState({});

  const [showLoader, setShowLoader] = useAtom(loaderAtom);
  const [showOtp, setShowOtp] = useState(false);
  const setMaskData = useSetAtom(maskedAtom);
  const BASE_PATH = useLocation();
  const query = new URLSearchParams(BASE_PATH.search);
  const access_token = query.get("access");
  const refresh_token = query.get("refresh");

  const updateUser = (user, cleanedBrand, callback = () => {}) => {
    const currentURL = window.location.href.replace(window.location.search, "");
    setCandidateData(user);
    setCandidateMessages(
      currentURL !== user.interview_url && !Constants.isWhiteListed(window.location.hostname)
        ? wrongURL(user.interview_url + window.location.search)
        : messages({
            ...user,
            branding: cleanedBrand,
          })
    );
    localStorage.setItem("user", encodeURIComponent(JSON.stringify(user)));
    const durationClean = duration(user?.interview?.interview_duration);
    localStorage.setItem("interview_duration", durationClean);
    callback();
  };

  const fetchTokenViaOtp = async () => {
    try {
      const { data } = await Request.post(API.GENERATE_OTP, {
        candidate_interview_id,
      });
      setMaskData(data);
      setShowLoader(false);
    } catch {
      setCandidateData((old) => ({ ...old }));
    }
  };

  useEffect(() => {
    const localWebview = localStorage.getItem('webview');
    localStorage.clear();
    localStorage.setItem(
      "APP_URL",
      window.location.pathname + window.location.search
    );
    localStorage.setItem("candidate_interview_id", candidate_interview_id);
    localStorage.setItem('webview', localWebview);

    const fetchData = async () => {
      setShowLoader(true);
      const HOST_NAME = window.location.hostname;
      let cleanedBrand = {};
      if (Constants.isWhiteListed(HOST_NAME)) {
        const brand = await fetchBrandByAccount(account_id);
        cleanedBrand = getBrandCleaned(brand);
        setBrandData(cleanedBrand);
        metaTags(cleanedBrand);
      } else {
        const brand = await fetchBrandData(HOST_NAME);
        cleanedBrand = getBrandCleaned(brand);
        setBrandData(cleanedBrand);
        metaTags(cleanedBrand);
      }

      try {
        if (access_token || refresh_token) {
          storeAccess(access_token, refresh_token);
          const { data: accountDetails } = await Request.get(
            API.CI + candidate_interview_id
          );
          updateUser(accountDetails, cleanedBrand, () => {
            setShowLoader(false);
          });
        } else {
          if (!localStorage.getItem("access_token")) {
            setShowOtp(true);
            fetchTokenViaOtp();
          }
        }

        // if (!access_token && !refresh_token) {
        //   const token = await fetchTokenByAccount(account_id);
        //   storeAccess(token.access, token.refresh);
        // } else {
        //   storeAccess(access_token, refresh_token);
        // }
        // const { data: accountDetails } = await Request.get(
        //   API.CI + candidate_interview_id
        // );
        // setCandidateData(accountDetails);
        // setCandidateMessages(messages({
        //   ...accountDetails,
        //   branding: cleanedBrand
        // }));
        // localStorage.setItem(
        //   "user",
        //   encodeURIComponent(JSON.stringify(accountDetails))
        // );
        // const durationClean = duration(accountDetails?.interview?.interview_duration);
        // localStorage.setItem("interview_duration", durationClean);
        // setShowLoader(false);
      } catch {
        fetchDummy();
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDummy = async () => {
    setShowLoader(true);
    await new Promise((r) => setTimeout(r, 2000));
    const response = { ...Constants.CandidateInterview };
    response.branding.default = true;
    setCandidateData(response);
    setCandidateMessages(messages(response));
    setShowLoader(false);
  };

  const afterOTPVerified = (userData) => {
    updateUser(userData, brandData, () => {
      setTimeout(() => { // fix memory leak
        setShowOtp(false);
      }, 1);
    });
    //
  };

  if (showOtp) {
    return (
      <NewScreenOTP branding={showLoader ? null : brandData}>
        {showLoader ? null : <OTPScreen callback={afterOTPVerified} />}
      </NewScreenOTP>
    );
  }

  return (
    <NewScreen
      branding={showLoader ? null : brandData}
      defaultMessage={candidateData?.default_msg}
      title={candidateMessages?.description}
      candidate={candidateData?.candidate}
      buttonDetails={{
        text: candidateMessages?.title,
        url: candidateMessages?.url,
      }}
    />
  );
}

export default WelcomeCandidate;
