import React from "react";
import PropTypes from "prop-types";
import { Bar, AudioBox } from "../AudioBar/AudioBar.style";

function AudioBarSync({ active = false }) {
  return (
    <AudioBox className={active ? "active" : ""}>
      <Bar className="audioBar" />
      <Bar className="audioBar" />
      <Bar className="audioBar" />
    </AudioBox>
  );
}

AudioBarSync.propTypes = {
  active: PropTypes.bool
};

export default AudioBarSync;
