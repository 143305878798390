import React, { useEffect, useState } from "react";

const Timer = () => {
  const [time, setTime] = useState(new Date(Date.now()));

  useEffect(() => {
    let timer = null;
    const counter = () => {
      timer = setTimeout(() => {
        setTime(new Date(Date.now()));
        counter();
      }, 60000);
    };
    counter();
    return () => clearTimeout(timer);
  }, []);

  return <span>{('0' + time.getHours()).slice(-2)}:{('0' + time.getMinutes()).slice(-2)}</span>;
};

export default Timer;
