export const omit = (obj) => {
  return Object.keys(obj)
    .filter((name) => Boolean(obj[name]))
    .reduce((acc, name) => ({ ...acc, [name]: obj[name] }), {});
};

export const removeAllChild = (parent) => {
  while (parent.lastChild) {
    parent.removeChild(parent.lastChild);
  }
};
