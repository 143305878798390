import { atom } from 'jotai';
import { loaderAtom } from './loaderAtom';

export const systemSpeakingAtom = atom(false);

export const showNextAtom = atom(false);

export const showFinishAtom = atom(false);

export const interviewAtom = atom({});

export const questionAtom = atom({});

export const initQuestionLoader = atom(true);

export const initQuestionLoaderBased = atom((get) => !get(loaderAtom) && get(initQuestionLoader));

export const noFaceAtom = atom(false);