import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import metaTags from "../../utils/meta-tags";
import Button from "../../Components/Button";
import { INTERVIEW_STATUS, APP_URL, COMPLETED } from "../../Constants";
import { loaderAtom } from "../../atoms/loaderAtom";
import { useSetAtom } from "jotai";
import {
  UserDetails,
  Wrapper,
  Logo,
  Header,
  UserTitle,
  UserSubTitle,
  UserIcon,
  Footer,
  MainContainer,
  Section,
  GlobalStyle
} from "./Exit.style";
import titleCase from "../../utils/title-case";
import getBrandCleaned from "../../utils/getBrandCleaned";

function Exit() {
  const history = useHistory();
  const [user, setUser] = useState({});
  const showLoader = useSetAtom(loaderAtom);
  const [brandData, setBrandData] = useState({});

  useEffect(() => {
    const userData = JSON.parse(
      decodeURIComponent(localStorage.getItem("user"))
    );
    setUser(userData);
    const cleanedBrand = getBrandCleaned(userData?.branding);
    setBrandData(cleanedBrand)
    metaTags(cleanedBrand);
    showLoader(false);
    const app_url = localStorage.getItem(APP_URL);
    const interview_status = localStorage.getItem(INTERVIEW_STATUS);

    if (interview_status !== COMPLETED) {
      history.push("/system-check");
    } else {
      localStorage.clear();
      localStorage.setItem(APP_URL, app_url);
    }
  }, [history, showLoader]);

  return (
    <Wrapper className="exit-page">
      <GlobalStyle />
      <Header>
        <Logo>
          <img
            className="logo"
            src={brandData?.brand_logo}
            alt="logo"
            loading="lazy"
          />
        </Logo>
        <UserDetails>
          <span>
            <UserTitle>
              {titleCase(user?.candidate?.first_name)} {titleCase(user?.candidate?.last_name)}
            </UserTitle>
            <UserSubTitle>{user?.interview?.name}</UserSubTitle>
          </span>
          <UserIcon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                fill="#fff"
                d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"
              />
            </svg>
          </UserIcon>
        </UserDetails>
      </Header>
      <Section>
        <MainContainer>
          <h1>
            {titleCase(user?.candidate?.first_name)}, Thank you so much for appearing in the
            interview.
          </h1>
          <p>We will get back to you as soon as possible.</p>
        </MainContainer>
        <Footer>
          <Button
            as="a"
            rounded
            large
            href={user?.redirect_url || brandData?.brand_url || "/"}
          >
            Exit
          </Button>
        </Footer>
      </Section>
    </Wrapper>
  );
}

export default Exit;
