import { useRef, useState } from "react";

function useSpeech({ text = null }) {
  const messageSpeech = useRef({});
  const [isSpeechError, setIsSpeechError] = useState(false);

  const onStart = () => {
    try {
      messageSpeech.current = new SpeechSynthesisUtterance();
      const voices = speechSynthesis.getVoices();
      messageSpeech.current.voice = voices[1];
      messageSpeech.current.voiceURI = "native";
      messageSpeech.current.volume = 1;
      messageSpeech.current.rate = 1;
      messageSpeech.current.pitch = 1;
      messageSpeech.current.text = text;
      messageSpeech.current.lang = "en-US";
      speechSynthesis.speak(messageSpeech.current);
      setIsSpeechError(false);
    } catch (error) {
      console.error(error);
      setIsSpeechError(true);
    }
  };

  return { messageSpeech: messageSpeech.current, start: onStart, isSpeechError };
}

export default useSpeech;
