import React from "react";
import MuteIcon from "./Icons/MuteIcon";
import UnMuteIcon from "./Icons/UnMuteIcon";
import MicStyles from "../Styles/Mic.module.css";


function MicShow({ audioState }) {
  return (
    <div className={MicStyles.Container}>
      <div className={MicStyles.Right}>
        <div className={MicStyles.MicWrap}>
          <span
            className={`${MicStyles.MicBg} ${
              audioState ? MicStyles.MicBgActive : ""
            }`}
          ></span>
          <div className={MicStyles.MicContainer}>
            {audioState ? <UnMuteIcon /> : <MuteIcon />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MicShow;
