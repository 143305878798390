import styled, { keyframes } from "styled-components";

export const InterviewPage = styled.div`
  height: 100%;
  background: #202124;
`;

export const WrapperHeader = styled.div`
  display: flex;
  padding: 10px;
  margin: 0 auto;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 375px) {
    padding: 10px 15px;
  }
`;

export const HeaderLeft = styled.div`
  text-align: left;
  flex: 0 0 50%;
`;

export const HeaderRight = styled.div`
  text-align: right;
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
`;

export const UserTitle = styled.span``;

export const UserSubTitle = styled.span`
  font-size: 10px;
  display: block;
`;

export const UserIcon = styled.i`
  padding: 8px 9px;
  background: #3d4143;
  border-radius: 50%;
  border: 1px solid #000;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 15px;
  }
`;

export const WrapperContainer = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

const blink = keyframes`
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.5
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
`;

export const LogoGray = styled.div`
  background: #36373a;//#4a4e51;
  padding: 8px;
  border-radius: 8px;
  display: inline-block;

  img {
    max-width: 120px;
    max-height: 50px;
    display: block;
  }
`;

export const CamIcon = styled.span`
  margin-right: 5px;
  position: relative;
  display: flex;

  .red-dot {
    position: absolute;
    width: 5px;
    height: 5px;
    background: #f00;
    border-radius: 50%;
    left: 10px;
    top: 12.5px;
    animation: ${blink} 2s linear infinite;
  }
`;

export const WrapperSplit = styled.div`
  background: #202124;
`;

export const Strip = styled.div`
  padding: 15px 15px 0;
`;

export const StripInner = styled.div`
  background: #36373a; // #3d4143;
  width: 100%;
  letter-spacing: .01785714em;
  font-size: .875rem;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
`;

export const WrapperScreen = styled.div`
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 20px;

  @media (orientation: landscape) and (min-width: 401px) and (max-width: 1023px) {
    margin-top: 10px;
  }

  @media (min-width: 1024px) {
    height: calc(100vh - 200px);
  }
`;

export const WrapperQuestion = styled.div`
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.1), 0 1px 2px -1px rgb(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #36373a;//#4a4e51;
  color: #fff;

  h1 {
    margin: 0;
    text-align: center;
  }

  @media (max-width: 400px) {
    height: 52vh;
  }

  @media (orientation: portrait) and (min-width: 401px) and (max-width: 1023px) {
    /* height: 63vh; */
    height: calc(100vh - 380px);
  }

  @media (orientation: landscape) and (min-width: 401px) and (max-width: 1023px) {
    height: calc(100vh - 300px);
    padding: 5px;

    h1 {
      font-size: 1.2rem;
    }
  }

  @media (orientation: landscape) and (min-width: 780px) {
    height: calc(100vh - 240px);
    padding: 5px;
  }

  @media (orientation: landscape) and (min-width: 844px) and (min-height: 768px) {
    height: 100%;
  }

  @media (min-width: 1024px) {
    flex: 1;
    height: 100%;
  }
`;

export const WrapperUsers = styled.div`
  width: 100%;
  gap: 15px;
  display: flex;

  @media (min-width: 1024px) {
    width: 300px;
    width: calc(100vw - 75%);
    height: 100%;
    flex-wrap: wrap;
    gap: 15px;
  }
`;

export const AudioRightTop = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
`;

export const UserDetails = styled.div`
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.1), 0 1px 2px -1px rgb(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background: #36373a; //#4a4e51;
  color: #fff;

  &.active {
    outline: 4px solid #669df6;
  }

  @media (max-width: 767px) {
    width: 50%;
    height: 120px;
  }

  @media (orientation: portrait) and (min-width: 768px) and (max-width: 1023px) {
    width: 50%;
    height: 190px;
  }

  @media (orientation: landscape) and (min-width: 768px) and (max-width: 1023px) {
    width: 50%;
    height: 80px;
  }

  @media (min-width: 1024px) {
    width: 100%;
  }
`;

export const WrapperButtons = styled.div`
  margin: 15px auto;
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  padding-left: 15px;
  padding-right: 15px;

  @media (orientation: landscape) and (min-width: 401px) and (min-height: 375px) {
    margin: 8px auto;
  }

  @media (orientation: landscape) and (min-width: 768px) and (max-width: 1023px) {
    height: 50px;
  }
`;

export const LeftDetails = styled.div`
  display: none;

  @media (min-width: 768px) {
    width: 33.33%;
    display: block;
  }
`;

export const CenterDetails = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (min-width: 768px) {
    width: 33.33%;
  }
`;

export const RightDetails = styled.div`
  text-align: right;
  display: none;

  @media (min-width: 768px) {
    width: 33.33%;
    display: block;
  }
`;

export const InfoButton = styled.button`
  border: 0;
  background: none;
  padding: 10px;
  width: 44px;
  height: 44px;
  border-radius: 50%;

  &:hover {
    background: #3d4143;
  }
`;

export const TimeInterDetails = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #fff;
`;

export const UserName = styled.div`
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: .625rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: .2px;

  @media (min-width: 768px) {
    left: 15px;
    bottom: 15px;
    font-size: .875rem;
  }
`;