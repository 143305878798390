export const STATUS_GOOD = "good";
export const STATUS_CHECKING = "checking";
export const STATUS_ERROR = "error";
export const STATUS_SLOW = "slow";

export const netestOptions = {
  slowSpeedThreshold: 100,
  numOfChecks: 3,
  failIfTooLong: true,
  timeUntilLoadFailure: 60000,
  loadFailureCheckInterval: 2000,
  imageSource:
    "https://upload.wikimedia.org/wikipedia/commons/b/b9/Pizigani_1367_Chart_1MB.jpg",
  imageSize: 1040,
};

export const statusText = {
  [STATUS_SLOW]: "Network is slow",
  [STATUS_CHECKING]: "Checking Network Speed....",
  [STATUS_ERROR]: "Network is Down",
  [STATUS_GOOD]: "Network is Good",
};
