const VI_STATUS = ['SCHEDULED', 'STARTED'];

export const INTERVIEW_STATUS = "interview_status";
export const APP_URL = "APP_URL";
export const COMPLETED = "COMPLETED";
export const PROCESSED = "PROCESSED";

export const Branding = {
		"brand_title": "Interview by CogniCue",
		"brand_description": "Automated Interview System",

		"brand_bg_color": "#000D21",
		"brand_gd_color": "#000821",
		"brand_hover_color": "#04dbec",
		"brand_text_color": "#ffffff",

		"brand_ico": "https://media.cognicue.in/icons/icon.svg",
		"brand_url": "https://www.cognicue.in",
		"brand_bg": "/assets/img/welcome-background.svg",
		"brand_logo": "https://media.cognicue.in/logos/logo-black.svg",
		"welcome_logo": "https://media.cognicue.in/logos/logo-white.svg",
        "interview_url": "https://services.cognicue.in/interview/cognicue/?utm_source=interview"
	}

export const CandidateInterview = {
		"candidate": {},
		"interview": {},
		"branding": Branding,
		"default_msg": "Hey there,",
		"interview_status": "COMPLETED",
		"tnc_url": "https://www.cognicue.in/terms-of-use/"
    }

export const BUTTON_TITLE = {
  'PRESS REC WHEN READY': 'Record',
  'STOP BUTTON': 'Stop',
  'PLAY VIDEO': 'Preview',
  'PAUSE VIDEO':  'Pause',
  'RECORD ANOTHER': 'Record Again'
}

export const BASE_INTERVIEWS = ["cognicue.in", "interview-cognicue-in.pages.dev"];

export const isWhiteListed = (host) => host ? BASE_INTERVIEWS.some((url) => host.endsWith(url)) : false;

export const BUTTON_TEXT = {
  default: "Try For Free",
  home: "Home"
}

export const TITLE_TEXT = {
  default: "Register for an Interview?",
  home: "Register for an Interview?"
}

export const VIDEO_RECORDING_LIMIT = 10000;
export const VIDEO_START_AFTER = 3000;

export default VI_STATUS;