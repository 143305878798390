import { COMPLETED, PROCESSED } from "../../Constants";

const messages = ({
  activate_date,
  deactivate_date,
  interview_status,
  interview,
  branding,
  candidate,
  status,
  questions,
}) => {
  const NO_INTERVIEW = {
    title: null,
    url: null,
    description: null,
  };
  if (!interview_status) return NO_INTERVIEW;

  const INTERVIEW_NAME = interview?.name;
  const END_POINT = branding?.brand_url;

  const ERROR = {
    title: "HOME",
    url: END_POINT,
    description: "This link has been invalid.",
  };

  const EMPTY = {
    title: "",
    url: "",
    description: "",
  };

  const INTERVIEW_STATE = {
    SCHEDULED: {
      title: "Yes",
      url: "/system-check",
      description: "Are you ready for the " + INTERVIEW_NAME + " interview?",
    },
    STARTED: {
      title: "Go Ahead",
      url: "/system-check",
      description: "Your " + INTERVIEW_NAME + " interview is already started.",
    },
    COMPLETED: {
      title: "Home",
      url: END_POINT,
      description: "Your " + INTERVIEW_NAME + " interview has been completed.",
    },
    PROCESSED: {
      title: "Home",
      url: END_POINT,
      description: "Your " + INTERVIEW_NAME + " interview has been processed.",
    },
    INCOMPLETE: {
      title: "Home",
      url: END_POINT,
      description: "Your " + INTERVIEW_NAME + " interview is incomplete.",
    },
    BLOCKED: {
      title: "Home",
      url: END_POINT,
      description: "Your " + INTERVIEW_NAME + " interview has been blocked.",
    },
  };

  const NO_QUESTIONS = {
    title: "Home",
    url: END_POINT,
    description:
      "Oops! There seems to be a problem with the interview. Please contact the team to resolve the issue.",
  };

  const beforeActiveMessage = {
    description:
      "Your " + INTERVIEW_NAME + " interview has not been activated yet.",
    url: END_POINT,
    title: "Home",
  };
  const afterActiveMessage = {
    description: "Your " + INTERVIEW_NAME + " interview has been expired.",
    url: END_POINT,
    title: "Home",
  };

  const beforeActive = activate_date
    ? new Date() <= new Date(activate_date)
    : false;
  const afterActive = deactivate_date
    ? new Date() >= new Date(deactivate_date)
    : false;

  const getMessage = beforeActive
    ? beforeActiveMessage
    : afterActive
    ? afterActiveMessage
    : INTERVIEW_STATE[interview_status] || EMPTY;
  const isValid = candidate.status && interview.status && status;

  if (isValid && interview_status === PROCESSED) {
    return INTERVIEW_STATE.PROCESSED;
  }

  if (isValid && interview_status === COMPLETED) {
    return INTERVIEW_STATE.COMPLETED;
  }

  if (isValid) {
    return questions ? getMessage : NO_QUESTIONS;
  }

  return ERROR;
};

export const wrongURL = (url) => ({
  url,
  description: 'It appears that you entered the incorrect URL. Please click the button below to continue.',
  title: 'Continue'
})

export const RESEND_ERROR_MESSAGE = "Please try again to get OTP";
export const NOT_VALID_MESSAGE = "Please enter valid OTP";

export default messages;
