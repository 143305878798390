const HAS_TTS = "has_tts";
const HAS_TTS_Q = "has_tts_Q";

const storageService = {
  setTTS: (value) => {
    localStorage.setItem(HAS_TTS, value);
  },
  getTTS: () => {
    return localStorage.getItem(HAS_TTS);
  },
  setTTS_Q: (value) => {
    localStorage.setItem(HAS_TTS_Q, value);
  },
  getTTS_Q: () => {
    return localStorage.getItem(HAS_TTS_Q);
  },
};

export default storageService;
