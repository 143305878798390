import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import toast, { Toaster } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ReactHintFactory from "react-hint";
import Home from "./Pages/Home";
import WelcomeCandidate from "./Pages/welcome-candidate";
import WelcomeAccount from "./Pages/WelcomeAccount";

import SystemCheck from "./Pages/SystemCheck";
import Interview from "./Pages/Interview";
import Exit from "./Pages/Exit";

import FACSRoot, { FACS, FACSLogger } from "./Components/FACS";
import { UserMedia } from "./Components/UserMedia";
import Loader from "./Components/Loader";
import "react-hint/css/index.css";
import storeWebView from "./utils/storeWebView";

const ReactHint = ReactHintFactory(React);

function PrivateRoute({ children, ...rest }) {
  const isAuthenticated = localStorage.getItem("user") ? true : false;
  const welcomePage = localStorage.getItem("APP_URL") || "/";

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect to={{ pathname: welcomePage, state: { from: location } }} />
        )
      }
    />
  );
}

function App() {
  const logger = new FACSLogger();

  const facs = new FACS(toast, logger);
  const media = new UserMedia(toast);

  useEffect(() => {
    storeWebView();
    const userData = JSON.parse(
      decodeURIComponent(localStorage.getItem("user"))
    );
    const favicon = document.getElementById("favicon");
    if (userData?.branding?.brand_ico) {
      favicon.href = userData?.branding?.brand_ico;
    }
  }, []);

  return (
    <Router>
      <div className={"App " + (isMobile ? "is-mobile" : "")}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route exact path="/:account_id/:candidate_interview_id">
            <WelcomeCandidate />
          </Route>

          <PrivateRoute path="/system-check">
            <SystemCheck facs={facs} media={media} logger={logger} />
          </PrivateRoute>

          <PrivateRoute path="/interview">
            <Interview facs={facs} media={media} logger={logger} />
          </PrivateRoute>

          <PrivateRoute path="/exit">
            <Exit />
          </PrivateRoute>

          <Route exact path="/:account_id">
            <WelcomeAccount />
          </Route>
          <Route path="*">
            <Home />
          </Route>
        </Switch>

        <FACSRoot />
      </div>
      <Loader />
      <ReactHint attribute="data-title" events />
      <Toaster />
    </Router>
  );
}

export default App;
