import React from "react";
import {
  GlobalStyle,
  Container,
  LeftContainer,
  RightContainer,
  SkewContainer,
  SkewBox,
  DetailsLeft,
  Terms,
  DetailsRight,
  UserTitle,
  UserSubTitle,
  UserButton,
  ExternalLink,
  Image,
  Title,
  SubTitle,
} from "./new-screen.style";
import titleCase from "../../utils/title-case";

const NewScreen = ({
  branding,
  candidate,
  defaultMessage,
  title,
  buttonDetails,
}) => {
  return (
    <>
      <GlobalStyle
        startColor={branding?.brand_bg_color}
        endColor={branding?.brand_gd_color}
        text={branding?.brand_text_color}
      />
      <Container level={0}>
        <LeftContainer>
          <SkewContainer>
            <SkewBox />
          </SkewContainer>
        </LeftContainer>
      </Container>
      <Container level={1}>
        <LeftContainer>
          {branding && (
            <>
              <DetailsLeft>
                <ExternalLink href={branding?.brand_url}>
                  <Image
                    src={
                      branding?.default
                        ? branding?.welcome_logo
                        : branding?.brand_logo
                    }
                    alt="logo"
                    loading="lazy"
                    decoding="async"
                  />
                </ExternalLink>
                <Title>{branding?.brand_title}</Title>
                <SubTitle>{branding?.brand_description}</SubTitle>
              </DetailsLeft>
              <Terms>
                Before signing in please check the{" "}
                <ExternalLink
                  href={branding?.brand_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  terms &amp; conditions
                </ExternalLink>
              </Terms>
            </>
          )}
        </LeftContainer>
        <RightContainer>
          <DetailsRight>
            <UserTitle>
              {candidate?.first_name &&
                "Hi, " + titleCase(candidate?.first_name)}
              {defaultMessage && defaultMessage}
            </UserTitle>
            {title && <UserSubTitle>{title}</UserSubTitle>}
            {buttonDetails?.url && buttonDetails?.text && (
              <UserButton
                href={buttonDetails?.url}
                textColor={branding?.brand_text_color}
                bgColor={branding?.brand_hover_color}
              >
                {buttonDetails?.text}
              </UserButton>
            )}
          </DetailsRight>
        </RightContainer>
      </Container>
    </>
  );
};

export default NewScreen;
