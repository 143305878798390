import React, { useEffect, useState, memo } from "react";
import { useParams } from "react-router-dom";
import { useAtom, useSetAtom } from "jotai";
import Request, { API } from "../../API";
import { ResetButton } from "./OTPScreen.style";
import { timerAtom } from "../../atoms/timerAtom";

const Timer = (props) => {
  const { time = 30, callback } = props;
  const [state, setState] = useAtom(timerAtom);

  useEffect(() => {
    const timer = setInterval(() => {
      setState(old => old + 1);
    }, 1000);

    if (state.start >= time) {
      clearInterval(timer);
      callback(true);
    }

    return () => {
      clearInterval(timer);
    };
  }, [callback, setState, state, time]);

  return (
    <>{state >= time ? null : time - state}</>
  )
}

const ResendButtonComponent = (props) => {
  const { callback, disabled } = props;
  console.log(disabled)
  const [isDisable, setIsDisable] = useState(disabled);
  const [isStart, setIsStart] = useState(false);
  const { candidate_interview_id } = useParams();
  const resetStart = useSetAtom(timerAtom);

  useEffect(() => {
    setIsDisable(disabled);
  }, [disabled])

  const resendEvent = async () => {
    console.log('click');
    resetStart(0)
    setIsStart(true);
    callback(true);
    try {
      await Request.post(API.GENERATE_OTP, {
        candidate_interview_id,
        resend: true
      });
      setIsDisable(true);
    } catch {
      callback(false);
      setIsDisable(false);
    }
  };

  return (
    <ResetButton
      disabled={isDisable}
      onClick={resendEvent}
    >
      {isStart && <Timer callback={setIsDisable} />} Resend OTP
    </ResetButton>
  );
};

export default memo(ResendButtonComponent);