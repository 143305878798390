import Request, { API } from "../API";

export const INTERVIEW_ANALYTICS = "interview_analytics";
export const AUDIO_ANALYTICS = "audio_analytics";

/**
 * save facs data
 * @param {string} id - question id
 * @param {object} data - JSON object with facs data(facs_data)
 * @returns Promise
 */
export async function saveFacs(id, data) {
  try {
    return await Request.patch(API.CIQ + id, data);
  } catch (error) {
    return error;
  }
}

/**
 * Save media service to send audio and video data on server as per question id
 * @param {string} id - question ID
 * @param {object} video - video binary data
 * @param {object} audio - audio binary data
 */
export async function saveMedia(id, video, audio) {
  const mediaForm = new FormData();
  if (video?.type) {
    mediaForm.append("video_response", video, "video.webm");
  }

  if (audio?.type) {
    mediaForm.append("audio_response", audio, "audio.wav");
  }

  try {
    return await Request.patch(API.CIQ + id, mediaForm);
  } catch (error) {
    return error;
  }
}

/**
 * Save media service to send audio data with finish event on server as per question id
 * @param {string} id - question ID
 * @param {object} video - video binary data
 */
 export async function saveAudioWithFinish(id, audio) {
  const mediaForm = new FormData();

  if (audio?.type) {
    mediaForm.append("audio_response", audio, "audio.wav");
  }

  mediaForm.append("analytics_tasks", JSON.stringify([AUDIO_ANALYTICS, INTERVIEW_ANALYTICS]))

  try {
    return await Request.patch(API.CIQ + id, mediaForm);
  } catch (error) {
    return error;
  }
}

/**
 * Save media service to send video data on server as per question id
 * @param {string} id - question ID
 * @param {object} video - video binary data
 */
 export async function saveVideo(id, video) {
  const mediaForm = new FormData();

  if (!video?.type) {
    throw new Error('No Video Data Id: ' + id); 
  }

  mediaForm.append("video_response", video, "video.webm");

  try {
    return await Request.patch(API.CIQ + id, mediaForm);
  } catch (error) {
    return error;
  }
}

/**
 * send finish interview, required question id
 * @param {string} id - question id
 */
export async function completeInterview(id) {
  try {
    return await Request.patch(API.CIQ + id, {
      analytics_tasks: [AUDIO_ANALYTICS, INTERVIEW_ANALYTICS],
    });
  } catch (error) {
    return error;
  }
}

/**
 * Next Button Save media service to send audio and video data on server as per question id
 * @param {string} id - question ID
 * @param {object} video - video binary data
 * @param {object} audio - audio binary data
 */
export async function nextButtonSaveMedia(id, video, audio) {
  const mediaForm = new FormData();
  if (video?.type) {
    mediaForm.append("video_response", video, "video.webm");
  }

  if (audio?.type) {
    mediaForm.append("audio_response", audio, "audio.wav");
  }

  mediaForm.append("analytics_tasks", JSON.stringify([AUDIO_ANALYTICS]));

  try {
    return await Request.patch(API.CIQ + id, mediaForm);
  } catch (error) {
    return error;
  }
}

/**
 * fetch next question of interview
 * @param {object} data - JSON object
 * @returns Promise
 */
export async function getNextQuestion(data) {
  try {
    return await Request.post(API.CIQ, data);
  } catch (error) {
    return error;
  }
}
