const mobileConfig = {
  landscape: {
    width: 640,
    height: 480
  },
  portrait: {
    width: 250,
    height: 440
  }
}

export default mobileConfig;