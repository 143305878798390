import styled from "styled-components";

export const Title = styled.h2`
  color: var(--text-color);
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
  margin: 0;

  @media (min-width: 768px) {
    color: #111827;
  }
`;

export const SubTitle = styled.p`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--text-color);
  margin: 0.75rem 0;

  @media (min-width: 768px) {
    color: #111827;
  }
`;

export const HighLight = styled.strong`
  color: var(--text-color);
  @media (min-width: 768px) {
    color: #15803d;
  }
`;

export const Input = styled.div`
  input {
    padding: 0.25rem 0.5rem;
    border: 1px solid ${(props) => props.error ? '#ef4444' : '#6b7280'};
    border-radius: 0.25rem;
    width: 100%;
    display: block;
    font-family: inherit;
    font-size: 100%;
    margin-bottom: 0.25rem;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:  center;
  margin-top: 1rem;
`;

export const ResetButton = styled.button`
  border: 0;
  background: transparent;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--text-color);
  padding: 0;

  &:disabled {
    cursor: default;
  }

  @media (min-width: 768px) {
    color: #111827;
  }
`;

export const SubmitButton = styled.button`
  color: var(--text-color);
  background-color: var(--start-bg);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-radius: 0.375rem;
  border: 0;
  font-family: inherit;

  &:hover {
    filter: brightness(1.5);
  }

  &:disabled {
    cursor: default;
  }

  @media (max-width: 767px) {
    border: 1px solid var(--text-color);
  }
`;

export const Error = styled.p`
  margin: 0;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #ef4444;
`;