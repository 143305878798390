import React from "react";
import { useAtomValue } from "jotai";
import { systemSpeakingAtom } from "../../atoms/interviewAtoms";
import VI_STATUS from "../../Constants";
import MicShow from "../Mic";
import {
  AudioRightTop,
  UserDetails,
  UserName,
} from "../../Pages/Interview/Interview.style";
import AudioBarSync from "../AudioBarSync";

const SystemSpeaking = ({ interview_status = '' }) => {
  const systemSpeaking = useAtomValue(systemSpeakingAtom);

  return (
    <UserDetails className={systemSpeaking ? 'active': ''}>
      <MicShow audioState={systemSpeaking} />
      {VI_STATUS.includes(interview_status) && (
        <AudioRightTop>
          <AudioBarSync active={systemSpeaking} />
        </AudioRightTop>
      )}
      <UserName>AI Interviewer</UserName>
    </UserDetails>
  );
};

export default SystemSpeaking;
