import * as Sentry from "@sentry/react";

function storeWebView() {
  try {
    const url = new URLSearchParams(window.location.search);
    localStorage.setItem('webview', url.get('webview'));
  } catch (err) {
    Sentry.captureException(err);
  }
}

export default storeWebView;