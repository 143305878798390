import React from 'react';
import { isMobile } from "react-device-detect";
import getLandscape from '../utils/get-landscape.helper';
import mobileConfig from '../utils/mobile-orientation.constant';
import { removeAllChild } from '../lib/helpers';
const affdex = window.affdex;

export function FACSLogger(){
  let _data = [];

  this.data = () => {
    let data = _data;
    _data = []
    return data;
  }

  this.getData = () => {
    return _data;
  }

  this.log = (data) => {
    _data.push(data)
  }
}

const default_config = {
  showSystemReadySuccessMessage: true,
  showMicSuccessMessage: true,
  showCameraSuccessMessage: true,
}

export function FACS(alert, logger){
    const States = {
        CONSENT: "CONSENT",
        LOADING: "LOADING",
        RECORDING: "RECORDING",
        PLAYBACK: "PLAYBACK",
        SUMMARY: "SUMMARY"
    };

    let current_state = States.CONSENT
      , s = !1
      , i = 0
      , l = 0
      , d = !0
      , c = null
      , m = !1
      , q = !1;

    const u = 5;

    let b = logger
      , w = alert;

    this.state = () => current_state;

    this.onConnect = () => {};

    this.onReady = () => {};

    this.onChange = () => {};

    this.onCamFail = () => {};

    this.onMicFail = () => {};

    this.setShowNext = () => {};

    this.config = {
      ...default_config
    };

    this.setConfig = (options = {}) => {
      this.config = {
        ...this.config,
        ...options
      }
    };

    this.resetConfig = () => {
      this.config = {
        ...default_config
      }
    }

    this.isReady = false;

    this.isCameraReady = false;

    this.isMicrophoneReady = false;

    const checkReadyness = () => {
      let requiredPermissions = [
        this.isCameraReady,
        this.isMicrophoneReady
      ]
      if(requiredPermissions.every(e=>e)){
        this.isReady = true;
        init();
      }
    };

    const grantMicroPhone = () => {
      navigator.permissions.query({name: 'microphone'}).then( res => {
        if(res.state === 'granted'){
          // w.success(messages.micSuccess);
          // mic connected success message
          w.success(
            <span className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" className="icon-space" viewBox="0 0 48 48"><path d="M24 26.85q-2.15 0-3.6-1.55-1.45-1.55-1.45-3.75V9q0-2.1 1.475-3.55Q21.9 4 24 4t3.575 1.45Q29.05 6.9 29.05 9v12.55q0 2.2-1.45 3.75-1.45 1.55-3.6 1.55Zm0-11.4ZM22.5 42v-6.8q-5.3-.55-8.9-4.45-3.6-3.9-3.6-9.2h3q0 4.55 3.225 7.65Q19.45 32.3 24 32.3q4.55 0 7.775-3.1Q35 26.1 35 21.55h3q0 5.3-3.6 9.2-3.6 3.9-8.9 4.45V42ZM24 23.85q.9 0 1.475-.675.575-.675.575-1.625V9q0-.85-.6-1.425Q24.85 7 24 7t-1.45.575q-.6.575-.6 1.425v12.55q0 .95.575 1.625T24 23.85Z"/></svg>
              {messages.micSuccess}
            </span>
          );
          this.isMicrophoneReady = true;
          checkReadyness();
        } else if (res.state === 'prompt') {
          // Waiting for user approval
        }
        else{
          // w.error(messages.micFailure);
          // mic connected error message
          w.error(
            <span className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" className="icon-space" viewBox="0 0 48 48"><path d="M24 26.85q-2.15 0-3.6-1.55-1.45-1.55-1.45-3.75V9q0-2.1 1.475-3.55Q21.9 4 24 4t3.575 1.45Q29.05 6.9 29.05 9v12.55q0 2.2-1.45 3.75-1.45 1.55-3.6 1.55Zm0-11.4ZM22.5 42v-6.8q-5.3-.55-8.9-4.45-3.6-3.9-3.6-9.2h3q0 4.55 3.225 7.65Q19.45 32.3 24 32.3q4.55 0 7.775-3.1Q35 26.1 35 21.55h3q0 5.3-3.6 9.2-3.6 3.9-8.9 4.45V42ZM24 23.85q.9 0 1.475-.675.575-.675.575-1.625V9q0-.85-.6-1.425Q24.85 7 24 7t-1.45.575q-.6.575-.6 1.425v12.55q0 .95.575 1.625T24 23.85Z"/></svg>
              {messages.micFailure}
            </span>
          );
          this.onMicFail();
        }
        res.onchange = ((e)=>{
            if (e.type === 'change'){
              this.onChange();

              const newState = e.target.state
              if (newState === 'denied') {
                // w.error(messages.micFailure);
                // mic connected error message
                w.error(
                  <span className="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" className="icon-space" viewBox="0 0 48 48"><path d="M24 26.85q-2.15 0-3.6-1.55-1.45-1.55-1.45-3.75V9q0-2.1 1.475-3.55Q21.9 4 24 4t3.575 1.45Q29.05 6.9 29.05 9v12.55q0 2.2-1.45 3.75-1.45 1.55-3.6 1.55Zm0-11.4ZM22.5 42v-6.8q-5.3-.55-8.9-4.45-3.6-3.9-3.6-9.2h3q0 4.55 3.225 7.65Q19.45 32.3 24 32.3q4.55 0 7.775-3.1Q35 26.1 35 21.55h3q0 5.3-3.6 9.2-3.6 3.9-8.9 4.45V42ZM24 23.85q.9 0 1.475-.675.575-.675.575-1.625V9q0-.85-.6-1.425Q24.85 7 24 7t-1.45.575q-.6.575-.6 1.425v12.55q0 .95.575 1.625T24 23.85Z"/></svg>
                    {messages.micFailure}
                  </span>
                );
                this.onMicFail();

              } else if (newState === 'granted') {
                // mic connected success message
                w.success(
                  <span className="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" className="icon-space" viewBox="0 0 48 48"><path d="M24 26.85q-2.15 0-3.6-1.55-1.45-1.55-1.45-3.75V9q0-2.1 1.475-3.55Q21.9 4 24 4t3.575 1.45Q29.05 6.9 29.05 9v12.55q0 2.2-1.45 3.75-1.45 1.55-3.6 1.55Zm0-11.4ZM22.5 42v-6.8q-5.3-.55-8.9-4.45-3.6-3.9-3.6-9.2h3q0 4.55 3.225 7.65Q19.45 32.3 24 32.3q4.55 0 7.775-3.1Q35 26.1 35 21.55h3q0 5.3-3.6 9.2-3.6 3.9-8.9 4.45V42ZM24 23.85q.9 0 1.475-.675.575-.675.575-1.625V9q0-.85-.6-1.425Q24.85 7 24 7t-1.45.575q-.6.575-.6 1.425v12.55q0 .95.575 1.625T24 23.85Z"/></svg>
                    {messages.micSuccess}
                  </span>
                );
                this.isMicrophoneReady = true;
                checkReadyness();

              } else {
                // Thanks for reverting things back to normal
              }
            }
          })
      });
    };

    const grantCamera = () => {
      navigator.permissions.query({name: 'camera'}).then( res => {
        if(res.state === 'granted'){
          // w.success(messages.camSuccess);
          // camera connected error message
          w.success(
            <span className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" className="icon-space" viewBox="0 0 48 48"><path d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h26q1.2 0 2.1.9.9.9.9 2.1v10.75l8-8v20.5l-8-8V37q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h26V11H7v26Zm0 0V11v26Z"/></svg>
              {messages.camSuccess}
            </span>
          );
          this.isCameraReady = true;
          checkReadyness();
        } else if (res.state === 'prompt') {
          console.log('Waiting for user approval.')
        }
        else{
          // w.error(messages.camFailure);
          // camera connected error message
          w.error(
            <span className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" className="icon-space" viewBox="0 0 48 48"><path d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h26q1.2 0 2.1.9.9.9.9 2.1v10.75l8-8v20.5l-8-8V37q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h26V11H7v26Zm0 0V11v26Z"/></svg>
              {messages.camFailure}
            </span>
          );
          this.onCamFail();
        }
        res.onchange = ((e)=>{
            if (e.type === 'change'){
              this.onChange();

              const newState = e.target.state
              if (newState === 'denied') {
                // w.error(messages.camFailure);
                // camera connected error message
                w.error(
                  <span className="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" className="icon-space" viewBox="0 0 48 48"><path d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h26q1.2 0 2.1.9.9.9.9 2.1v10.75l8-8v20.5l-8-8V37q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h26V11H7v26Zm0 0V11v26Z"/></svg>
                    {messages.camFailure}
                  </span>
                );
                this.onCamFail();

              } else if (newState === 'granted') {
                // w.success(messages.camSuccess);
                // camera connected error message
                w.success(
                  <span className="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" className="icon-space" viewBox="0 0 48 48"><path d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h26q1.2 0 2.1.9.9.9.9 2.1v10.75l8-8v20.5l-8-8V37q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h26V11H7v26Zm0 0V11v26Z"/></svg>
                    {messages.camSuccess}
                  </span>
                );
                this.isCameraReady = true;
                checkReadyness();

              } else {
                console.log('Thanks for reverting things back to normal')
              }
            }
          })
      });
    };

    this.ready = () => {
      if(browserCheck()){
          current_state = States.LOADING;
          try{
            removeAllChild(document.getElementById("facevideo-node"));
            const webview = !!localStorage.getItem('webview');
            if (!webview) {
              grantMicroPhone();
              grantCamera();
            } else {
              this.onChange();
              this.isMicrophoneReady = true;
              this.isCameraReady = true;
              checkReadyness();
            }
          }
          catch{
            this.onConnect = () => {
              this.config.showMicSuccessMessage && w.success(messages.micSuccess);
              this.config.showCameraSuccessMessage && w.success(messages.camSuccess);
              this.isReady = true;
            }
            init();
          }
      }
      else {
        w.error(messages.incompatableBrowser)
      }
    }

    this.readyOnly = checkReadyness;

    this.start = (startFACS) => {
      current_state = startFACS?States.RECORDING:States.PLAYBACK;
      s=startFACS;
    }

    this.stop = () => {
      s=!1;
      this.isReady && c.stop();
    }

    const init = () => {
      Promise.all([C()]).then(e=>{
        w.success(e)
      }).catch(e=>{
        w.error(e)
      })
    }


      , C = ()=>new Promise((e,t)=>{
        const landscape = getLandscape();
        let a = document.getElementById("facevideo-node");
        const w = isMobile && !landscape ? mobileConfig.portrait.width : null;
        const h = isMobile && !landscape ? mobileConfig.portrait.height : null;
        const face = isMobile && !landscape ? affdex.FaceDetectorMode.SMALL_FACES : undefined;
        c = new affdex.CameraDetector(a, w, h, face);

        c.detectAllEmotions();
        c.detectAllExpressions();
        c.detectAllAppearance();
        c && !c.isRunning && c.start();

        c.addEventListener("onWebcamConnectSuccess", ()=>{
            //w.success(messages.camSuccess);
            this.onConnect();
        });

        c.addEventListener("onWebcamConnectFailure", ()=>{
            //t(messages.camFailure);
            //this.onCamFail();
        });

        c.addEventListener("onInitializeSuccess", ()=>{
            // this.config.showSystemReadySuccessMessage && e.success(messages.initializeFACS);
            this.config.showSystemReadySuccessMessage && e(
              <span className="flex" key={Math.random()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" className="icon-space" viewBox="0 0 48 48"><path d="m19.4 44-1-6.3q-.95-.35-2-.95t-1.85-1.25l-5.9 2.7L4 30l5.4-3.95q-.1-.45-.125-1.025Q9.25 24.45 9.25 24q0-.45.025-1.025T9.4 21.95L4 18l4.65-8.2 5.9 2.7q.8-.65 1.85-1.25t2-.9l1-6.35h9.2l1 6.3q.95.35 2.025.925Q32.7 11.8 33.45 12.5l5.9-2.7L44 18l-5.4 3.85q.1.5.125 1.075.025.575.025 1.075t-.025 1.05q-.025.55-.125 1.05L44 30l-4.65 8.2-5.9-2.7q-.8.65-1.825 1.275-1.025.625-2.025.925l-1 6.3ZM24 30.5q2.7 0 4.6-1.9 1.9-1.9 1.9-4.6 0-2.7-1.9-4.6-1.9-1.9-4.6-1.9-2.7 0-4.6 1.9-1.9 1.9-1.9 4.6 0 2.7 1.9 4.6 1.9 1.9 4.6 1.9Zm0-3q-1.45 0-2.475-1.025Q20.5 25.45 20.5 24q0-1.45 1.025-2.475Q22.55 20.5 24 20.5q1.45 0 2.475 1.025Q27.5 22.55 27.5 24q0 1.45-1.025 2.475Q25.45 27.5 24 27.5Zm0-3.5Zm-2.2 17h4.4l.7-5.6q1.65-.4 3.125-1.25T32.7 32.1l5.3 2.3 2-3.6-4.7-3.45q.2-.85.325-1.675.125-.825.125-1.675 0-.85-.1-1.675-.1-.825-.35-1.675L40 17.2l-2-3.6-5.3 2.3q-1.15-1.3-2.6-2.175-1.45-.875-3.2-1.125L26.2 7h-4.4l-.7 5.6q-1.7.35-3.175 1.2-1.475.85-2.625 2.1L10 13.6l-2 3.6 4.7 3.45q-.2.85-.325 1.675-.125.825-.125 1.675 0 .85.125 1.675.125.825.325 1.675L8 30.8l2 3.6 5.3-2.3q1.2 1.2 2.675 2.05Q19.45 35 21.1 35.4Z"/></svg>
                {messages.initializeFACS}
              </span>
            );
            this.onReady();
        });

        c.addEventListener("onImageResultsSuccess", P)

        })

      , P = e=>{
        if (current_state === States.RECORDING && s) {
            if (!m){
              i = Date.now();
              m = !0;
            }

            let data;
            if (e.length > 0) {
              data = Object.assign({}, e[0].emotions, e[0].expressions)
            }
            else {
              data = {};
            }
            b.log(data);
            // eslint-disable-next-line no-unused-vars
            const t = S();
            if (l > u && d ) {
              d = !1;
              w.error(messages.noFace, {timeout: 0});
              this.setShowNext(false);
              w.count = 1;
              q = !0;
            }
            if (e.length > 0) {
              if (!d) {
                d = !0;
              }
              l = 0;
              // remove all toast(notification alert)
              w.dismiss();
              w.count = 0;
              if (q){
                this.setShowNext(true);
                w.count = 0;
              }
            }
            else {
              l++
            }
        }
    }
      , S = ()=>Date.now() - i;

    const browserCheck = ()=>!0;

    const messages = {
      incompatableBrowser: 'Unsupported browser',
      camFailure: 'Camera not connected',
      camSuccess: 'Camera connected',
      micSuccess: 'Mic connected',
      micFailure: 'Mic not connected',
      initializeFACS: 'System is ready',
      noFace: isMobile ? 'No face detected.': 'No face detected.',
    }
}


const FACSRoot = () => {
  return (
    <div id="facevideo-node" style={{display: 'none'}}></div>
  )
}

export default FACSRoot
