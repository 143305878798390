import React, { useReducer, useCallback, memo } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  Title,
  SubTitle,
  HighLight,
  Input,
  Buttons,
  SubmitButton,
  Error,
} from "./OTPScreen.style";
import Request, { API } from "../../API";
import { useAtomValue } from "jotai";
import maskedAtom from "../../atoms/maskedAtom";
import ResendButtonComponent from "./ResendButton.component";
import * as ACTION from "../../store/actions/otpformAction";
import reducer, { initState } from "../../store/reducers/otpformReducer";
import { NOT_VALID_MESSAGE, RESEND_ERROR_MESSAGE } from "./messages";
import { storeAccess } from "../../utils/storeAccess";

const OTPScreen = (props) => {
  const { callback } = props;
  const { candidate_interview_id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const maskData = useAtomValue(maskedAtom);
  const [state, dispatch] = useReducer(reducer, initState);

  const eventSubmit = async () => {
    dispatch({ type: ACTION.SUBMIT });
    try {
      const { data } = await Request.post(API.VERIFY_OTP, {
        candidate_interview_id,
        otp: state.otpValue,
      });
      storeAccess(data?.access, data?.refresh);
      const params = new URLSearchParams({
        access: data?.access,
        refresh: data?.refresh,
      });
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
      const userData = await Request.get(API.CI + candidate_interview_id);
      callback(userData.data);
    } catch (error) {
      console.log("Error", error);
      dispatch({ type: ACTION.NOT_VALID });
    }
    dispatch({ type: ACTION.SUBMITTED });
  };

  const onChange = useCallback((event) => {
    dispatch({ type: ACTION.INPUT, payload: event.target.value });
  }, []);

  const ref = useCallback((node) => {
    if (node) {
      node.focus();
    }
  }, []);

  const resendClicked = (status) => {
    if (status) {
      dispatch({ type: ACTION.RESET });
    } else {
      dispatch({ type: ACTION.RESEND_ERROR });
    }
  };

  return (
    <div>
      <Title>Please enter a valid email OTP.</Title>
      <SubTitle>
        Email OTP from <HighLight>{maskData?.candidate_email}</HighLight>
      </SubTitle>
      <Input error={state.NOT_VALID}>
        <input
          ref={ref}
          type="text"
          onChange={onChange}
          defaultValue={state.otpValue}
        />
      </Input>
      {state.NOT_VALID ? <Error>{NOT_VALID_MESSAGE}</Error> : null}
      {state.RESEND_ERROR ? <Error>{RESEND_ERROR_MESSAGE}</Error> : null}
      <Buttons>
        <ResendButtonComponent
          disabled={state.resendIsDisable}
          callback={resendClicked}
        />
        <SubmitButton disabled={state.submitIsDisable} onClick={eventSubmit}>
          {state.submitText}
        </SubmitButton>
      </Buttons>
    </div>
  );
};

export default memo(OTPScreen);
