import { isMobile } from "react-device-detect";
import { REQUIRED_PERCENTAGE } from "../Pages/SystemCheck/Constants";

export function getValidPercentage(data, index) {
  const newData = data.slice(index, data.length);
  const emptyObjects = newData.filter((obj) => !Object.keys(obj).length);
  const percentage = (emptyObjects.length / newData.length) * 100;
  if (isMobile && percentage <= REQUIRED_PERCENTAGE.mobile) {
    return false;
  }

  if (!isMobile && percentage <= REQUIRED_PERCENTAGE.desktop) {
    return false;
  }

  return true;
}

export function getInterviewStatus() {
  return localStorage.getItem("interview_status") === "COMPLETED";
}
