import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAtomValue, useSetAtom } from "jotai";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import VI_STATUS from "../../Constants";

import Question from "../../Components/Question";
import NextButton from "../../Components/NextButton";
import FinishButton from "../../Components/FinishButton";
import AudioBar from "../../Components/AudioBar";
import Timer from "../../Components/Timer";
import MicCandidate from "../../Components/MicCandidate";
import {
  systemSpeakingAtom,
  interviewAtom,
  questionAtom,
  showNextAtom,
  showFinishAtom,
  noFaceAtom,
  initQuestionLoaderBased,
} from "../../atoms/interviewAtoms";
import { loaderAtom } from "../../atoms/loaderAtom";
import { syncUserActionAtom, transcriptAtom } from "../../atoms/audioAtom";
import titleCase from "../../utils/title-case";
import metaTags from "../../utils/meta-tags";
import {
  CamIcon,
  WrapperSplit,
  WrapperScreen,
  WrapperButtons,
  WrapperQuestion,
  WrapperUsers,
  AudioRightTop,
  UserDetails,
  LeftDetails,
  CenterDetails,
  RightDetails,
  InfoButton,
  TimeInterDetails,
  UserName,
  InterviewPage,
  Strip,
  StripInner,
} from "./Interview.style";
import InterViewCircle from "./InterViewCircle";
import InterViewFaceCheck from "./InterViewFaceCheck";
import SystemSpeaking from "../../Components/SystemSpeaking";

const UserSpeaking = ({ interview_status, user, media }) => {
  const systemSpeaking = useAtomValue(systemSpeakingAtom);
  const syncUserAction = useAtomValue(syncUserActionAtom);
  return (
    <UserDetails className={!systemSpeaking && syncUserAction ? 'active': ''}>
      {VI_STATUS.includes(interview_status) && (
        <>
          <MicCandidate audioState={!systemSpeaking && syncUserAction} />
          {!systemSpeaking && (
            <AudioRightTop>
              <AudioBar media={media} />
            </AudioRightTop>
          )}
        </>
      )}
      <UserName>
        {titleCase(user?.candidate?.first_name)}{" "}
        {titleCase(user?.candidate?.last_name)}
      </UserName>
    </UserDetails>
  );
};

const Buttons = ({ resetTranscript, logger, facs, media }) => {
  const initQuestionStatus = useAtomValue(initQuestionLoaderBased);
  const noFace = useAtomValue(noFaceAtom);
  const nextButton = useAtomValue(showNextAtom);
  const finishButton = useAtomValue(showFinishAtom);

  if (initQuestionStatus) {
    return <InterViewCircle logger={logger} />;
  }

  if (noFace) {
    return <InterViewFaceCheck />;
  }

  if (nextButton) {
    return (
      <NextButton
        resetTranscript={resetTranscript}
        facs={facs}
        media={media}
        logger={logger}
      />
    );
  }

  if (finishButton) {
    return <FinishButton facs={facs} media={media} logger={logger} />;
  }

  return null;
};

function Interview({ facs, media, logger }) {
  const history = useHistory();
  const [user, setUser] = useState({});
  const setInterview = useSetAtom(interviewAtom);
  const setQuestion = useSetAtom(questionAtom);
  const setShowLoader = useSetAtom(loaderAtom);
  const setTranscript = useSetAtom(transcriptAtom);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    console.log("Web Speech API Not Support");
  }

  const listenContinuously = () =>
    SpeechRecognition.startListening({
      continuous: true,
      language: "en-IN",
    });

  // eslint-disable-next-line no-unused-vars
  const [transcribing, setTranscribing] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [clearTranscriptOnListen, setClearTranscriptOnListen] = useState(true);

  const { transcript, interimTranscript, finalTranscript, resetTranscript } =
    useSpeechRecognition({ transcribing, clearTranscriptOnListen });

  useEffect(() => {
    if (interimTranscript !== "") {
      //console.log('Got interim result:', interimTranscript)
    }
    if (finalTranscript !== "") {
      //console.log('Got final result:', finalTranscript)
    }
  }, [interimTranscript, finalTranscript]);

  useEffect(() => {
    setTranscript(transcript);
    // eslint-disable-next-line
  }, [transcript]);

  useEffect(() => {
    const userData = JSON.parse(
      decodeURIComponent(localStorage.getItem("user"))
    );
    setUser(userData);
    metaTags(userData?.branding);
    const interviewData = {
      interview_name: userData.interview.name,
      interview_duration: localStorage.getItem("interview_duration"),
      interview_started: localStorage.getItem("interview_started"),
      interview_status: localStorage.getItem("interview_status"),
    };
    setInterview(interviewData);

    const questionData = {
      question_text: localStorage.getItem("question_text"),
      question_duration: parseInt(localStorage.getItem("question_duration")),
    };

    setQuestion(questionData);

    if (VI_STATUS.includes(userData.interview_status)) {
      facs.onChange = () => {
        setShowLoader(true);
      };
      facs.onReady = () => {
        setShowLoader(false);
      };
      facs.onCamFail = () => {
        setShowLoader(false);
      };
      facs.onMicFail = () => {
        setShowLoader(false);
      };
      if (!facs.isReady) {
        setShowLoader(true);
        facs.ready();
      }
      facs.start(true);
    } else {
      history.push(localStorage.getItem("APP_URL"));
    }

    document.body.classList.add("interviewPage");

    return () => {
      document.body.classList.remove("interviewPage");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <InterviewPage>
        <Strip>
          <StripInner>
            <CamIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#fff"
                  d="M15 8v8H5V8h10m1-2H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4V7c0-.55-.45-1-1-1z"
                />
              </svg>
              <span className="red-dot"></span>
            </CamIcon>
            This session is being recorded
          </StripInner>
        </Strip>
        <WrapperSplit>
          <WrapperScreen>
            <WrapperQuestion>
              <Question
                listenContinuously={listenContinuously}
                facs={facs}
                media={media}
                logger={logger}
                resetTranscript={resetTranscript}
              />
            </WrapperQuestion>
            <WrapperUsers>
              <SystemSpeaking interview_status={user.interview_status} />
              <UserSpeaking
                interview_status={user.interview_status}
                media={media}
                user={user}
              />
            </WrapperUsers>
          </WrapperScreen>
          <WrapperButtons>
            <LeftDetails>
              <TimeInterDetails>
                <Timer /> | <span>{titleCase(user?.interview?.name)}</span>
              </TimeInterDetails>
            </LeftDetails>
            <CenterDetails>
              <Buttons
                resetTranscript={resetTranscript}
                facs={facs}
                media={media}
                logger={logger}
              />
            </CenterDetails>
            <RightDetails>
              <InfoButton>
                <svg
                  focusable="false"
                  aria-hidden="true"
                  width="24px"
                  viewBox="0 0 24 24"
                  data-testid="InfoIcon"
                  tabIndex="-1"
                  title="Info"
                >
                  <path
                    fill="#fff"
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
                  ></path>
                </svg>
              </InfoButton>
            </RightDetails>
          </WrapperButtons>
        </WrapperSplit>
      </InterviewPage>
      {/* <ScreenRotate facs={facs} /> */}
    </>
  );
}

export default Interview;
