import styled, { keyframes, css } from "styled-components";

const pulseAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .6;
  }
`;

export const LoadingBox = styled.div`
  z-index: 99;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  transform: translateY(80px);
  width: 300px;
  height: 100px;
`;

export const LoadingBar = styled.div`
  position: relative;
  width: 300px;
  height: 10px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  transform: translateY(30px);
`;

export const LoadingStatus = styled.div`
  width: ${(props) => props.width || 0}%;
  animation: ${(props) => props.width === 100 ? `none` : css`${pulseAnimation} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite`};
  height: 100%;
  background-color: ${(props) => props.state};
  transition: width 100ms linear;
  position: absolute;
  top: 0;
  left: 0;
`;

export const LoadingValues = styled.div`
  transform: translateY(20px);
  color: white;
  display: flex;
  justify-content: space-between;
`;

export const ErrorButton = styled.button`
  transform: translateY(40px);
  margin: 0 auto;
  display: block;
  padding: 10px 18px;
  background: #fff;
  border: 0;
  border-radius: 8px;
  color: #000;
  cursor: pointer;
`;
