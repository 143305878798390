import { Branding } from "../Constants";
import { omit } from "../lib/helpers";

const getBrandCleaned = (brand) => {
  return {
    ...omit(Branding),
    ...omit(brand || {}),
    ...(!brand && { default: true }),
  }
}

export default getBrandCleaned;